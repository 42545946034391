import React, { useState, useEffect, useContext } from 'react';
import listaPilotosContext from '../../context/listaPilotos/listaPilotosContext';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import AlertaContext from '../../context/alertas/alertaContext';
import MainNavigation from '../partials/MainNavigation';
import { Link } from 'react-router-dom';
import '../../design/styles/strategy-results.scss';

const Estrategia = () => {

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    let index = localStorage.getItem('index');
    let currentRace = JSON.parse(localStorage.getItem('currentRace'));

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {actualizarParticipante} = listaContext;
    const {participantesInscritosWarm, obtenerParticipantesWarm} = listaContext;
    const {participantesInscritosBox, obtenerParticipantesBox} = listaContext;
    const {participantesInscritosPaddock, obtenerParticipantesPaddock} = listaContext;
    const {participantesInscritosWomen, obtenerParticipantesWomen} = listaContext;

    const [selectedOption, setSelectedOption] = useState(null);

    const [selectedOptionPilotDnf, setSelectedOptionPilotDnf] = useState(null);

    const [selectedOptionPilotDhl, setSelectedOptionPilotDhl] = useState(null);

    // Extraer concursos de state inicial
    const pilotosContext = useContext(listaPilotosContext);
    const {pilotos, obtenerPilotos} = pilotosContext;

    const options = [
        '1º',
        '2º',
        '3º',
        '4º',
        '5º',
        '6º',
        '7º',
        '8º',
        '9º',
        '10º',
        '11º',
        '12º',
        '13º',
        '14º',
        '15º',
        '16º',
        '17º',
        '18º',
        '19º',
        '20º',
        'DNF',
        'DNS'
    ];

    const [isOpen, setIsOpen] = useState(false);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    const [isOpenPilotDnf, setIsOpenPilotDnf] = useState(false);

    const togglingPilotDnf = () => setIsOpenPilotDnf(!isOpenPilotDnf);

    const onOptionClickedPilotDnf = value => () => {
        setSelectedOptionPilotDnf(value);
        setIsOpenPilotDnf(false);
    };

    const [isOpenPilotDhl, setIsOpenPilotDhl] = useState(false);

    const togglingPilotDhl = () => setIsOpenPilotDhl(!isOpenPilotDhl);

    const onOptionClickedPilotDhl = value => () => {
        setSelectedOptionPilotDhl(value);
        setIsOpenPilotDhl(false);
    };

    const [puntos, guardarPuntos] = useState({
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: '',
        p8: '',
        p9: '',
        p10: '',
        p11: '',
        p12: '',
        p13: '',
        p14: '',
        p15: '',
        p16: '',
        p17: '',
        p18: '',
        p19: '',
        p20: ''
    });
    const {p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20} = puntos;

    const [selectedOptionPilotOne, setSelectedOptionPilotOne] = useState(null);

    const [isOpenPilotOne, setIsOpenPilotOne] = useState(false);

    const togglingPilotOne = (event) => {
        setIsOpenPilotOne(!isOpenPilotOne)
    };

    const onOptionClickedPilotOne = value => () => {
        setSelectedOptionPilotOne(value);
        setIsOpenPilotOne(false);
    };

    const [selectedOptionPilotTwo, setSelectedOptionPilotTwo] = useState(null);

    const [isOpenPilotTwo, setIsOpenPilotTwo] = useState(false);

    const togglingPilotTwo = (event) => {
        setIsOpenPilotTwo(!isOpenPilotTwo)
    };

    const onOptionClickedPilotTwo = value => () => {
        setSelectedOptionPilotTwo(value);
        setIsOpenPilotTwo(false);
    };

    const [selectedOptionPilotThree, setSelectedOptionPilotThree] = useState(null);

    const [isOpenPilotThree, setIsOpenPilotThree] = useState(false);

    const togglingPilotThree = (event) => {
        setIsOpenPilotThree(!isOpenPilotThree)
    };

    const onOptionClickedPilotThree = value => () => {
        setSelectedOptionPilotThree(value);
        setIsOpenPilotThree(false);
    };

    const [selectedOptionPilotFour, setSelectedOptionPilotFour] = useState(null);

    const [isOpenPilotFour, setIsOpenPilotFour] = useState(false);

    const togglingPilotFour = (event) => {
        setIsOpenPilotFour(!isOpenPilotFour)
    };

    const onOptionClickedPilotFour = value => () => {
        setSelectedOptionPilotFour(value);
        setIsOpenPilotFour(false);
    };

    const [selectedOptionPilotFive, setSelectedOptionPilotFive] = useState(null);

    const [isOpenPilotFive, setIsOpenPilotFive] = useState(false);

    const togglingPilotFive = (event) => {
        setIsOpenPilotFive(!isOpenPilotFive)
    };

    const onOptionClickedPilotFive = value => () => {
        setSelectedOptionPilotFive(value);
        setIsOpenPilotFive(false);
    };

    const [selectedOptionPilotSix, setSelectedOptionPilotSix] = useState(null);

    const [isOpenPilotSix, setIsOpenPilotSix] = useState(false);

    const togglingPilotSix = (event) => {
        setIsOpenPilotSix(!isOpenPilotSix)
    };

    const onOptionClickedPilotSix = value => () => {
        setSelectedOptionPilotSix(value);
        setIsOpenPilotSix(false);
    };

    const [selectedOptionPilotSeven, setSelectedOptionPilotSeven] = useState(null);

    const [isOpenPilotSeven, setIsOpenPilotSeven] = useState(false);

    const togglingPilotSeven = (event) => {
        setIsOpenPilotSeven(!isOpenPilotSeven)
    };

    const onOptionClickedPilotSeven = value => () => {
        setSelectedOptionPilotSeven(value);
        setIsOpenPilotSeven(false);
    };

    const [selectedOptionPilotEight, setSelectedOptionPilotEight] = useState(null);

    const [isOpenPilotEight, setIsOpenPilotEight] = useState(false);

    const togglingPilotEight = (event) => {
        setIsOpenPilotEight(!isOpenPilotEight)
    };

    const onOptionClickedPilotEight = value => () => {
        setSelectedOptionPilotEight(value);
        setIsOpenPilotEight(false);
    };

    const [selectedOptionPilotNine, setSelectedOptionPilotNine] = useState(null);

    const [isOpenPilotNine, setIsOpenPilotNine] = useState(false);

    const togglingPilotNine = (event) => {
        setIsOpenPilotNine(!isOpenPilotNine)
    };

    const onOptionClickedPilotNine = value => () => {
        setSelectedOptionPilotNine(value);
        setIsOpenPilotNine(false);
    };

    const [selectedOptionPilotTen, setSelectedOptionPilotTen] = useState(null);

    const [isOpenPilotTen, setIsOpenPilotTen] = useState(false);

    const togglingPilotTen = (event) => {
        setIsOpenPilotTen(!isOpenPilotTen)
    };

    const onOptionClickedPilotTen = value => () => {
        setSelectedOptionPilotTen(value);
        setIsOpenPilotTen(false);
    };

    const [selectedOptionPilotEleven, setSelectedOptionPilotEleven] = useState(null);

    const [isOpenPilotEleven, setIsOpenPilotEleven] = useState(false);

    const togglingPilotEleven = (event) => {
        setIsOpenPilotEleven(!isOpenPilotEleven)
    };

    const onOptionClickedPilotEleven = value => () => {
        setSelectedOptionPilotEleven(value);
        setIsOpenPilotEleven(false);
    };

    const [selectedOptionPilotTwelve, setSelectedOptionPilotTwelve] = useState(null);

    const [isOpenPilotTwelve, setIsOpenPilotTwelve] = useState(false);

    const togglingPilotTwelve = (event) => {
        setIsOpenPilotTwelve(!isOpenPilotTwelve)
    };

    const onOptionClickedPilotTwelve = value => () => {
        setSelectedOptionPilotTwelve(value);
        setIsOpenPilotTwelve(false);
    };

    const [selectedOptionPilotThirteen, setSelectedOptionPilotThirteen] = useState(null);

    const [isOpenPilotThirteen, setIsOpenPilotThirteen] = useState(false);

    const togglingPilotThirteen = (event) => {
        setIsOpenPilotThirteen(!isOpenPilotThirteen)
    };

    const onOptionClickedPilotThirteen = value => () => {
        setSelectedOptionPilotThirteen(value);
        setIsOpenPilotThirteen(false);
    };

    const [selectedOptionPilotForteen, setSelectedOptionPilotForteen] = useState(null);

    const [isOpenPilotForteen, setIsOpenPilotForteen] = useState(false);

    const togglingPilotForteen = (event) => {
        setIsOpenPilotForteen(!isOpenPilotForteen)
    };

    const onOptionClickedPilotForteen = value => () => {
        setSelectedOptionPilotForteen(value);
        setIsOpenPilotForteen(false);
    };

    const [selectedOptionPilotFifteen, setSelectedOptionPilotFifteen] = useState(null);

    const [isOpenPilotFifteen, setIsOpenPilotFifteen] = useState(false);

    const togglingPilotFifteen = (event) => {
        setIsOpenPilotFifteen(!isOpenPilotFifteen)
    };

    const onOptionClickedPilotFifteen = value => () => {
        setSelectedOptionPilotFifteen(value);
        setIsOpenPilotFifteen(false);
    };

    const [selectedOptionPilotSixteen, setSelectedOptionPilotSixteen] = useState(null);

    const [isOpenPilotSixteen, setIsOpenPilotSixteen] = useState(false);

    const togglingPilotSixteen = (event) => {
        setIsOpenPilotSixteen(!isOpenPilotSixteen)
    };

    const onOptionClickedPilotSixteen = value => () => {
        setSelectedOptionPilotSixteen(value);
        setIsOpenPilotSixteen(false);
    };

    const [selectedOptionPilotSeventeen, setSelectedOptionPilotSeventeen] = useState(null);

    const [isOpenPilotSeventeen, setIsOpenPilotSeventeen] = useState(false);

    const togglingPilotSeventeen = (event) => {
        setIsOpenPilotSeventeen(!isOpenPilotSeventeen)
    };

    const onOptionClickedPilotSeventeen = value => () => {
        setSelectedOptionPilotSeventeen(value);
        setIsOpenPilotSeventeen(false);
    };

    const [selectedOptionPilotEighteen, setSelectedOptionPilotEighteen] = useState(null);

    const [isOpenPilotEighteen, setIsOpenPilotEighteen] = useState(false);

    const togglingPilotEighteen = (event) => {
        setIsOpenPilotEighteen(!isOpenPilotEighteen)
    };

    const onOptionClickedPilotEighteen = value => () => {
        setSelectedOptionPilotEighteen(value);
        setIsOpenPilotEighteen(false);
    };

    const [selectedOptionPilotNineteen, setSelectedOptionPilotNineteen] = useState(null);

    const [isOpenPilotNineteen, setIsOpenPilotNineteen] = useState(false);

    const togglingPilotNineteen = (event) => {
        setIsOpenPilotNineteen(!isOpenPilotNineteen)
    };

    const onOptionClickedPilotNineteen = value => () => {
        setSelectedOptionPilotNineteen(value);
        setIsOpenPilotNineteen(false);
    };

    const [selectedOptionPilotTwenty, setSelectedOptionPilotTwenty] = useState(null);

    const [isOpenPilotTwenty, setIsOpenPilotTwenty] = useState(false);

    const togglingPilotTwenty = (event) => {
        setIsOpenPilotTwenty(!isOpenPilotTwenty)
    };

    const onOptionClickedPilotTwenty = value => () => {
        setSelectedOptionPilotTwenty(value);
        setIsOpenPilotTwenty(false);
    };

    useEffect(() => {
        obtenerPilotos();
        obtenerParticipantesPaddock('65c2a9de784e25b32d5224ab');
        obtenerParticipantesWarm('65c2abef784e25b32d5224ae');
        obtenerParticipantesBox('65c2abdb784e25b32d5224ad');
        obtenerParticipantesWomen('65c2abca784e25b32d5224ac');
    }, [])

    const onChange = (e) => {
        guardarPuntos({
            ...puntos,
            [e.target.name] : [e.target.value]
        });
    }
    
    const saveInfo = () => {
        let puntosRedBull = 0,
            puntosMercedes = 0,
            puntosFerrari = 0,
            puntosMcLaren = 0,
            puntosAlpine = 0,
            puntosAlphaTauri = 0,
            puntosAstonMartin = 0,
            puntosWilliams = 0,
            puntosAlfaRomeo = 0,
            puntosHaas = 0;

        if (selectedOptionPilotOne === null || p1 === '' ||
            selectedOptionPilotTwo === null || p2 === '' ||
            selectedOptionPilotThree === null || p3 === '' ||
            selectedOptionPilotFour === null || p4 === '' ||
            selectedOptionPilotFive === null || p5 === '' ||
            selectedOptionPilotSix === null || p6 === '' ||
            selectedOptionPilotSeven === null || p7 === '' ||
            selectedOptionPilotEight === null || p8 === '' ||
            selectedOptionPilotNine === null || p9 === '' ||
            selectedOptionPilotTen === null || p10 === '' ||
            selectedOptionPilotEleven === null || p11 === '' ||
            selectedOptionPilotTwelve === null || p12 === '' ||
            selectedOptionPilotThirteen === null || p13 === '' ||
            selectedOptionPilotForteen === null || p14 === '' ||
            selectedOptionPilotFifteen === null || p15 === '' ||
            selectedOptionPilotSixteen === null || p16 === '' ||
            selectedOptionPilotSeventeen === null || p17 === '' ||
            selectedOptionPilotEighteen === null || p18 === '' ||
            selectedOptionPilotNineteen === null || p19 === '' ||
            selectedOptionPilotTwenty === null || p20 === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
            return;
        }

        const arrayValidation = [
            selectedOptionPilotOne,
            selectedOptionPilotTwo,
            selectedOptionPilotThree,
            selectedOptionPilotFour,
            selectedOptionPilotFive,
            selectedOptionPilotSix,
            selectedOptionPilotSeven,
            selectedOptionPilotEight,
            selectedOptionPilotNine,
            selectedOptionPilotTen,
            selectedOptionPilotEleven,
            selectedOptionPilotTwelve,
            selectedOptionPilotThirteen,
            selectedOptionPilotForteen,
            selectedOptionPilotFifteen,
            selectedOptionPilotSixteen,
            selectedOptionPilotSeventeen,
            selectedOptionPilotEighteen,
            selectedOptionPilotNineteen,
            selectedOptionPilotTwenty,
        ]

        let repetido = false;
        for (let i = 0; i < arrayValidation.length; i++) {
            for (let j = 0; j < arrayValidation.length; j++) {
                if (arrayValidation[i] == arrayValidation[j] && i != j) { //revisamos que i sea diferente de j, para que no compare el mismo elemento exacto.
                    repetido= true;
                }
            }
        }

        if(repetido === true) {
            mostrarAlerta('Hay un piloto repetido.', 'alerta-error')
            return
        }

        mostrarAlerta('Datos correctos', 'alerta-ok');

        if (selectedOptionPilotOne === 'Max Verstappen*' || selectedOptionPilotOne === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Max Verstappen*' || selectedOptionPilotTwo === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Max Verstappen*' || selectedOptionPilotThree === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Max Verstappen*' || selectedOptionPilotFour === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Max Verstappen*' || selectedOptionPilotFive === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Max Verstappen*' || selectedOptionPilotSix === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Max Verstappen*' || selectedOptionPilotSeven === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Max Verstappen*' || selectedOptionPilotEight === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Max Verstappen*' || selectedOptionPilotNine === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Max Verstappen*' || selectedOptionPilotTen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Max Verstappen*' || selectedOptionPilotEleven === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Max Verstappen*' || selectedOptionPilotTwelve === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Max Verstappen*' || selectedOptionPilotThirteen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Max Verstappen*' || selectedOptionPilotForteen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Max Verstappen*' || selectedOptionPilotFifteen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Max Verstappen*' || selectedOptionPilotSixteen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Max Verstappen*' || selectedOptionPilotSeventeen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Max Verstappen*' || selectedOptionPilotEighteen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Max Verstappen*' || selectedOptionPilotNineteen === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Max Verstappen*' || selectedOptionPilotTwenty === 'Sergio Pérez*') {
            puntosRedBull = puntosRedBull + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Lewis Hamilton*' || selectedOptionPilotOne === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Lewis Hamilton*' || selectedOptionPilotTwo === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Lewis Hamilton*' || selectedOptionPilotThree === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Lewis Hamilton*' || selectedOptionPilotFour === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Lewis Hamilton*' || selectedOptionPilotFive === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Lewis Hamilton*' || selectedOptionPilotSix === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Lewis Hamilton*' || selectedOptionPilotSeven === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Lewis Hamilton*' || selectedOptionPilotEight === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Lewis Hamilton*' || selectedOptionPilotNine === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Lewis Hamilton*' || selectedOptionPilotTen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Lewis Hamilton*' || selectedOptionPilotEleven === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Lewis Hamilton*' || selectedOptionPilotTwelve === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Lewis Hamilton*' || selectedOptionPilotThirteen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Lewis Hamilton*' || selectedOptionPilotForteen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Lewis Hamilton*' || selectedOptionPilotFifteen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Lewis Hamilton*' || selectedOptionPilotSixteen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Lewis Hamilton*' || selectedOptionPilotSeventeen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Lewis Hamilton*' || selectedOptionPilotEighteen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Lewis Hamilton*' || selectedOptionPilotNineteen === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Lewis Hamilton*' || selectedOptionPilotTwenty === 'George Russell*') {
            puntosMercedes = puntosMercedes + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Carlos Sainz*' || selectedOptionPilotOne === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Carlos Sainz*' || selectedOptionPilotTwo === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Carlos Sainz*' || selectedOptionPilotThree === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Carlos Sainz*' || selectedOptionPilotFour === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Carlos Sainz*' || selectedOptionPilotFive === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Carlos Sainz*' || selectedOptionPilotSix === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Carlos Sainz*' || selectedOptionPilotSeven === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Carlos Sainz*' || selectedOptionPilotEight === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Carlos Sainz*' || selectedOptionPilotNine === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Carlos Sainz*' || selectedOptionPilotTen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Carlos Sainz*' || selectedOptionPilotEleven === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Carlos Sainz*' || selectedOptionPilotTwelve === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Carlos Sainz*' || selectedOptionPilotThirteen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Carlos Sainz*' || selectedOptionPilotForteen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Carlos Sainz*' || selectedOptionPilotFifteen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Carlos Sainz*' || selectedOptionPilotSixteen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Carlos Sainz*' || selectedOptionPilotSeventeen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Carlos Sainz*' || selectedOptionPilotEighteen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Carlos Sainz*' || selectedOptionPilotNineteen === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Carlos Sainz*' || selectedOptionPilotTwenty === 'Charles Leclerc*') {
            puntosFerrari = puntosFerrari + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Lando Norris*' || selectedOptionPilotOne === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Lando Norris*' || selectedOptionPilotTwo === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Lando Norris*' || selectedOptionPilotThree === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Lando Norris*' || selectedOptionPilotFour === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Lando Norris*' || selectedOptionPilotFive === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Lando Norris*' || selectedOptionPilotSix === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Lando Norris*' || selectedOptionPilotSeven === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Lando Norris*' || selectedOptionPilotEight === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Lando Norris*' || selectedOptionPilotNine === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Lando Norris*' || selectedOptionPilotTen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Lando Norris*' || selectedOptionPilotEleven === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Lando Norris*' || selectedOptionPilotTwelve === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Lando Norris*' || selectedOptionPilotThirteen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Lando Norris*' || selectedOptionPilotForteen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Lando Norris*' || selectedOptionPilotFifteen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Lando Norris*' || selectedOptionPilotSixteen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Lando Norris*' || selectedOptionPilotSeventeen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Lando Norris*' || selectedOptionPilotEighteen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Lando Norris*' || selectedOptionPilotNineteen === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Lando Norris*' || selectedOptionPilotTwenty === 'Oscar Piastri*') {
            puntosMcLaren = puntosMcLaren + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Esteban Ocon*' || selectedOptionPilotOne === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Esteban Ocon*' || selectedOptionPilotTwo === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Esteban Ocon*' || selectedOptionPilotThree === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Esteban Ocon*' || selectedOptionPilotFour === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Esteban Ocon*' || selectedOptionPilotFive === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Esteban Ocon*' || selectedOptionPilotSix === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Esteban Ocon*' || selectedOptionPilotSeven === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Esteban Ocon*' || selectedOptionPilotEight === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Esteban Ocon*' || selectedOptionPilotNine === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Esteban Ocon*' || selectedOptionPilotTen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Esteban Ocon*' || selectedOptionPilotEleven === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Esteban Ocon*' || selectedOptionPilotTwelve === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Esteban Ocon*' || selectedOptionPilotThirteen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Esteban Ocon*' || selectedOptionPilotForteen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Esteban Ocon*' || selectedOptionPilotFifteen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Esteban Ocon*' || selectedOptionPilotSixteen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Esteban Ocon*' || selectedOptionPilotSeventeen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Esteban Ocon*' || selectedOptionPilotEighteen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Esteban Ocon*' || selectedOptionPilotNineteen === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Esteban Ocon*' || selectedOptionPilotTwenty === 'Pierre Gasly*') {
            puntosAlpine = puntosAlpine + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Daniel Ricciardo*' || selectedOptionPilotOne === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Daniel Ricciardo*' || selectedOptionPilotTwo === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Daniel Ricciardo*' || selectedOptionPilotThree === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Daniel Ricciardo*' || selectedOptionPilotFour === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Daniel Ricciardo*' || selectedOptionPilotFive === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Daniel Ricciardo*' || selectedOptionPilotSix === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Daniel Ricciardo*' || selectedOptionPilotSeven === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Daniel Ricciardo*' || selectedOptionPilotEight === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Daniel Ricciardo*' || selectedOptionPilotNine === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Daniel Ricciardo*' || selectedOptionPilotTen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Daniel Ricciardo*' || selectedOptionPilotEleven === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Daniel Ricciardo*' || selectedOptionPilotTwelve === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Daniel Ricciardo*' || selectedOptionPilotThirteen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Daniel Ricciardo*' || selectedOptionPilotForteen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Daniel Ricciardo*' || selectedOptionPilotFifteen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Daniel Ricciardo*' || selectedOptionPilotSixteen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Daniel Ricciardo*' || selectedOptionPilotSeventeen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Daniel Ricciardo*' || selectedOptionPilotEighteen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Daniel Ricciardo*' || selectedOptionPilotNineteen === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Daniel Ricciardo*' || selectedOptionPilotTwenty === 'Yuki Tsunoda*') {
            puntosAlphaTauri = puntosAlphaTauri + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Lance Stroll*' || selectedOptionPilotOne === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Lance Stroll*' || selectedOptionPilotTwo === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Lance Stroll*' || selectedOptionPilotThree === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Lance Stroll*' || selectedOptionPilotFour === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Lance Stroll*' || selectedOptionPilotFive === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Lance Stroll*' || selectedOptionPilotSix === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Lance Stroll*' || selectedOptionPilotSeven === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Lance Stroll*' || selectedOptionPilotEight === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Lance Stroll*' || selectedOptionPilotNine === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Lance Stroll*' || selectedOptionPilotTen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Lance Stroll*' || selectedOptionPilotEleven === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Lance Stroll*' || selectedOptionPilotTwelve === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Lance Stroll*' || selectedOptionPilotThirteen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Lance Stroll*' || selectedOptionPilotForteen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Lance Stroll*' || selectedOptionPilotFifteen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Lance Stroll*' || selectedOptionPilotSixteen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Lance Stroll*' || selectedOptionPilotSeventeen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Lance Stroll*' || selectedOptionPilotEighteen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Lance Stroll*' || selectedOptionPilotNineteen === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Lance Stroll*' || selectedOptionPilotTwenty === 'Fernando Alonso*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Alexander Albon*' || selectedOptionPilotOne === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Alexander Albon*' || selectedOptionPilotTwo === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Alexander Albon*' || selectedOptionPilotThree === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Alexander Albon*' || selectedOptionPilotFour === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Alexander Albon*' || selectedOptionPilotFive === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Alexander Albon*' || selectedOptionPilotSix === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Alexander Albon*' || selectedOptionPilotSeven === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Alexander Albon*' || selectedOptionPilotEight === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Alexander Albon*' || selectedOptionPilotNine === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Alexander Albon*' || selectedOptionPilotTen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Alexander Albon*' || selectedOptionPilotEleven === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Alexander Albon*' || selectedOptionPilotTwelve === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Alexander Albon*' || selectedOptionPilotThirteen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Alexander Albon*' || selectedOptionPilotForteen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Alexander Albon*' || selectedOptionPilotFifteen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Alexander Albon*' || selectedOptionPilotSixteen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Alexander Albon*' || selectedOptionPilotSeventeen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Alexander Albon*' || selectedOptionPilotEighteen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Alexander Albon*' || selectedOptionPilotNineteen === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Alexander Albon*' || selectedOptionPilotTwenty === 'Logan Sargeant*') {
            puntosWilliams = puntosWilliams + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Valtteri Bottas*' || selectedOptionPilotOne === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Valtteri Bottas*' || selectedOptionPilotTwo === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Valtteri Bottas*' || selectedOptionPilotThree === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Valtteri Bottas*' || selectedOptionPilotFour === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Valtteri Bottas*' || selectedOptionPilotFive === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Valtteri Bottas*' || selectedOptionPilotSix === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Valtteri Bottas*' || selectedOptionPilotSeven === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Valtteri Bottas*' || selectedOptionPilotEight === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Valtteri Bottas*' || selectedOptionPilotNine === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Valtteri Bottas*' || selectedOptionPilotTen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Valtteri Bottas*' || selectedOptionPilotEleven === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Valtteri Bottas*' || selectedOptionPilotTwelve === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Valtteri Bottas*' || selectedOptionPilotThirteen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Valtteri Bottas*' || selectedOptionPilotForteen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Valtteri Bottas*' || selectedOptionPilotFifteen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Valtteri Bottas*' || selectedOptionPilotSixteen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Valtteri Bottas*' || selectedOptionPilotSeventeen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Valtteri Bottas*' || selectedOptionPilotEighteen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Valtteri Bottas*' || selectedOptionPilotNineteen === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Valtteri Bottas*' || selectedOptionPilotTwenty === 'Guanyu Zhou*') {
            puntosAlfaRomeo = puntosAlfaRomeo + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Nico Hulkenberg*' || selectedOptionPilotOne === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Nico Hulkenberg*' || selectedOptionPilotTwo === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Nico Hulkenberg*' || selectedOptionPilotThree === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Nico Hulkenberg*' || selectedOptionPilotFour === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Nico Hulkenberg*' || selectedOptionPilotFive === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Nico Hulkenberg*' || selectedOptionPilotSix === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Nico Hulkenberg*' || selectedOptionPilotSeven === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Nico Hulkenberg*' || selectedOptionPilotEight === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Nico Hulkenberg*' || selectedOptionPilotNine === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Nico Hulkenberg*' || selectedOptionPilotTen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Nico Hulkenberg*' || selectedOptionPilotEleven === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Nico Hulkenberg*' || selectedOptionPilotTwelve === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Nico Hulkenberg*' || selectedOptionPilotThirteen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Nico Hulkenberg*' || selectedOptionPilotForteen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Nico Hulkenberg*' || selectedOptionPilotFifteen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Nico Hulkenberg*' || selectedOptionPilotSixteen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Nico Hulkenberg*' || selectedOptionPilotSeventeen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Nico Hulkenberg*' || selectedOptionPilotEighteen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Nico Hulkenberg*' || selectedOptionPilotNineteen === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Nico Hulkenberg*' || selectedOptionPilotTwenty === 'Kevin Magnussen*') {
            puntosHaas = puntosHaas + parseInt(p20);
        }

        participantesInscritosBox.map(participante => {

            let puntosTotales,
                puntosTotalesPiloto,
                puntosTotalesConstructor;

            if (!participante.puntos) {
                puntosTotales = 0;
            } else {
                puntosTotales = parseFloat(participante.puntos);
            }

            if (!participante.puntos_pick_piloto) {
                puntosTotalesPiloto = 0;
            } else {
                puntosTotalesPiloto = parseFloat(participante.puntos_pick_piloto);
            }

            if (!participante.puntos_pick_constructor) {
                puntosTotalesConstructor = 0;
            } else {
                puntosTotalesConstructor = parseFloat(participante.puntos_pick_constructor);
            }

            if (selectedOptionPilotOne === participante.pick_piloto_24 || selectedOptionPilotOne === participante.pick_piloto_24 + '*' || selectedOptionPilotOne === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p1)
                });
                puntosTotales = puntosTotales + parseInt(p1);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p1);
            }
            
            if (selectedOptionPilotOne === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p1) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p1) / 2);
            }
            if (selectedOptionPilotTwo === participante.pick_piloto_24 || selectedOptionPilotTwo === participante.pick_piloto_24 + '*' || selectedOptionPilotTwo === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p2)
                });
                puntosTotales = puntosTotales + parseInt(p2);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p2);
            }
            if (selectedOptionPilotTwo === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p2) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p2) / 2);
            }
            if (selectedOptionPilotThree === participante.pick_piloto_24 || selectedOptionPilotThree === participante.pick_piloto_24 + '*' || selectedOptionPilotThree === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p3)
                });
                puntosTotales = puntosTotales + parseInt(p3);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p3);
            }
            if (selectedOptionPilotThree === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p3) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p3) / 2);
            }
            if (selectedOptionPilotFour === participante.pick_piloto_24 || selectedOptionPilotFour === participante.pick_piloto_24 + '*' || selectedOptionPilotFour === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p4)
                });
                puntosTotales = puntosTotales + parseInt(p4);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p4);
            }
            if (selectedOptionPilotFour === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p4) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p4) / 2);
            }
            if (selectedOptionPilotFive === participante.pick_piloto_24 || selectedOptionPilotFive === participante.pick_piloto_24 + '*' || selectedOptionPilotFive === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p5)
                });
                puntosTotales = puntosTotales + parseInt(p5);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p5);
            }
            if (selectedOptionPilotFive === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p5) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p5) / 2);
            }
            if (selectedOptionPilotSix === participante.pick_piloto_24 || selectedOptionPilotSix === participante.pick_piloto_24 + '*' || selectedOptionPilotSix === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p6)
                });
                puntosTotales = puntosTotales + parseInt(p6);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p6);
            }
            if (selectedOptionPilotSix === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p6) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p6) / 2);
            }
            if (selectedOptionPilotSeven === participante.pick_piloto_24 || selectedOptionPilotSeven === participante.pick_piloto_24 + '*' || selectedOptionPilotSeven === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p7)
                });
                puntosTotales = puntosTotales + parseInt(p7);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p7);
            }
            if (selectedOptionPilotSeven === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p7) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p7) / 2);
            }
            if (selectedOptionPilotEight === participante.pick_piloto_24 || selectedOptionPilotEight === participante.pick_piloto_24 + '*' || selectedOptionPilotEight === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p8)
                });
                puntosTotales = puntosTotales + parseInt(p8);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p8);
            }
            if (selectedOptionPilotEight === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p8) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p8) / 2);
            }
            if (selectedOptionPilotNine === participante.pick_piloto_24 || selectedOptionPilotNine === participante.pick_piloto_24 + '*' || selectedOptionPilotNine === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p9)
                });
                puntosTotales = puntosTotales + parseInt(p9);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p9);
            }
            if (selectedOptionPilotNine === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p9) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p9) / 2);
            }
            if (selectedOptionPilotTen === participante.pick_piloto_24 || selectedOptionPilotTen === participante.pick_piloto_24 + '*' || selectedOptionPilotTen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p10)
                });
                puntosTotales = puntosTotales + parseInt(p10);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p10);
            }
            if (selectedOptionPilotTen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p10) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p10) / 2);
            }
            if (selectedOptionPilotEleven === participante.pick_piloto_24 || selectedOptionPilotEleven === participante.pick_piloto_24 + '*' || selectedOptionPilotEleven === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p11)
                });
                puntosTotales = puntosTotales + parseInt(p11);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p11);
            }
            if (selectedOptionPilotEleven === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p11) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p11) / 2);
            }
            if (selectedOptionPilotTwelve === participante.pick_piloto_24 || selectedOptionPilotTwelve === participante.pick_piloto_24 + '*' || selectedOptionPilotTwelve === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p12)
                });
                puntosTotales = puntosTotales + parseInt(p12);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p12);
            }
            if (selectedOptionPilotTwelve === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p12) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p12) / 2);
            }
            if (selectedOptionPilotThirteen === participante.pick_piloto_24 || selectedOptionPilotThirteen === participante.pick_piloto_24 + '*' || selectedOptionPilotThirteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p13)
                });
                puntosTotales = puntosTotales + parseInt(p13);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p13);
            }
            if (selectedOptionPilotThirteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p13) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p13) / 2);
            }
            if (selectedOptionPilotForteen === participante.pick_piloto_24 || selectedOptionPilotForteen === participante.pick_piloto_24 + '*' || selectedOptionPilotForteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p14)
                });
                puntosTotales = puntosTotales + parseInt(p14);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p14);
            }
            if (selectedOptionPilotForteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p14) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p14) / 2);
            }
            if (selectedOptionPilotFifteen === participante.pick_piloto_24 || selectedOptionPilotFifteen === participante.pick_piloto_24 + '*' || selectedOptionPilotFifteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p15)
                });
                puntosTotales = puntosTotales + parseInt(p15);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p15);
            }
            if (selectedOptionPilotFifteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p15) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p15) / 2);
            }
            if (selectedOptionPilotSixteen === participante.pick_piloto_24 || selectedOptionPilotSixteen === participante.pick_piloto_24 + '*' || selectedOptionPilotSixteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p16)
                });
                puntosTotales = puntosTotales + parseInt(p16);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p16);
            }
            if (selectedOptionPilotSixteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p16) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p16) / 2);
            }
            if (selectedOptionPilotSeventeen === participante.pick_piloto_24 || selectedOptionPilotSeventeen === participante.pick_piloto_24 + '*' || selectedOptionPilotSeventeen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p17)
                });
                puntosTotales = puntosTotales + parseInt(p17);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p17);
            }
            if (selectedOptionPilotSeventeen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p17) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p17) / 2);
            }
            if (selectedOptionPilotEighteen === participante.pick_piloto_24 || selectedOptionPilotEighteen === participante.pick_piloto_24 + '*' || selectedOptionPilotEighteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p18)
                });
                puntosTotales = puntosTotales + parseInt(p18);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p18);
            }
            if (selectedOptionPilotEighteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p18) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p18) / 2);
            }
            if (selectedOptionPilotNineteen === participante.pick_piloto_24 || selectedOptionPilotNineteen === participante.pick_piloto_24 + '*' || selectedOptionPilotNineteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p19)
                });
                puntosTotales = puntosTotales + parseInt(p19);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p19);
            }
            if (selectedOptionPilotNineteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p19) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p19) / 2);
            }
            if (selectedOptionPilotTwenty === participante.pick_piloto_24 || selectedOptionPilotTwenty === participante.pick_piloto_24 + '*' || selectedOptionPilotTwenty === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p20)
                });
                puntosTotales = puntosTotales + parseInt(p20);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p20);
            }
            if (selectedOptionPilotTwenty === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p20) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p20) / 2);
            }

            if (selectedOption === participante.pick_pregunta1_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta1_24: 20
                });
                puntosTotales = puntosTotales + 20;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta1_24: 0
                });
            }

            if (selectedOptionPilotDhl === participante.pick_pregunta2_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta2_24: 10
                });
                puntosTotales = puntosTotales + 10;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta2_24: 0
                });
            }

            if (selectedOptionPilotDnf === participante.pick_pregunta3_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta3_24: 20
                });
                puntosTotales = puntosTotales + 20;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta3_24: 0
                });
            }

            if (participante.pick_constructor_24 === 'Red Bull Racing' || participante.pick_constructor_24 === 'Red Bull Racing*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosRedBull
                });
                puntosTotales = puntosTotales + puntosRedBull;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRedBull;
            }

            if (participante.sorteo_constructor_24 === 'Red Bull Racing') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosRedBull / 2)
                });
                puntosTotales = puntosTotales + (puntosRedBull / 2);
            }

            if (participante.pick_constructor_24 === 'Mercedes' || participante.pick_constructor_24 === 'Mercedes*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMercedes
                });
                puntosTotales = puntosTotales + puntosMercedes;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMercedes;
            }

            if (participante.sorteo_constructor_24 === 'Mercedes') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosMercedes / 2)
                });
                puntosTotales = puntosTotales + (puntosMercedes / 2);
            }

            if (participante.pick_constructor_24 === 'Ferrari' || participante.pick_constructor_24 === 'Ferrari*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosFerrari
                });
                puntosTotales = puntosTotales + puntosFerrari;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosFerrari;
            }

            if (participante.sorteo_constructor_24 === 'Ferrari') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosFerrari / 2)
                });
                puntosTotales = puntosTotales + (puntosFerrari / 2);
            }

            if (participante.pick_constructor_24 === 'McLaren' || participante.pick_constructor_24 === 'McLaren*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMcLaren
                });
                puntosTotales = puntosTotales + puntosMcLaren;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMcLaren;
            }

            if (participante.sorteo_constructor_24 === 'McLaren') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosMcLaren / 2)
                });
                puntosTotales = puntosTotales + (puntosMcLaren / 2);
            }

            if (participante.pick_constructor_24 === 'Alpine' || participante.pick_constructor_24 === 'Alpine*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlpine
                });
                puntosTotales = puntosTotales + puntosAlpine;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlpine;
            }

            if (participante.sorteo_constructor_24 === 'Alpine') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlpine / 2)
                });
                puntosTotales = puntosTotales + (puntosAlpine / 2);
            }

            if (participante.pick_constructor_24 === 'RB' || participante.pick_constructor_24 === 'RB*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlphaTauri
                });
                puntosTotales = puntosTotales + puntosAlphaTauri;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlphaTauri;
            }

            if (participante.sorteo_constructor_24 === 'RB') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlphaTauri / 2)
                });
                puntosTotales = puntosTotales + (puntosAlphaTauri / 2);
            }

            if (participante.pick_constructor_24 === 'Aston Martin' || participante.pick_constructor_24 === 'Aston Martin*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAstonMartin
                });
                puntosTotales = puntosTotales + puntosAstonMartin;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAstonMartin;
            }

            if (participante.sorteo_constructor_24 === 'Aston Martin') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAstonMartin / 2)
                });
                puntosTotales = puntosTotales + (puntosAstonMartin / 2);
            }

            if (participante.pick_constructor_24 === 'Williams' || participante.pick_constructor_24 === 'Williams*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosWilliams
                });
                puntosTotales = puntosTotales + puntosWilliams;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosWilliams;
            }

            if (participante.sorteo_constructor_24 === 'Williams') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosWilliams / 2)
                });
                puntosTotales = puntosTotales + (puntosWilliams / 2);
            }

            if (participante.pick_constructor_24 === 'Kick Sauber' || participante.pick_constructor_24 === 'Kick Sauber*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlfaRomeo
                });
                puntosTotales = puntosTotales + puntosAlfaRomeo;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlfaRomeo;
            }

            if (participante.sorteo_constructor_24 === 'Kick Sauber') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlfaRomeo / 2)
                });
                puntosTotales = puntosTotales + (puntosAlfaRomeo / 2);
            }

            if (participante.pick_constructor_24 === 'Haas F1 Team' || participante.pick_constructor_24 === 'Haas F1 Team*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosHaas
                });
                puntosTotales = puntosTotales + puntosHaas;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosHaas;
            }

            if (participante.sorteo_constructor_24 === 'Haas F1 Team') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosHaas / 2)
                });
                puntosTotales = puntosTotales + (puntosHaas / 2);
            }

            if (participante.pick_constructor_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: 0
                });
            }

            if (participante.pick_piloto_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: 0
                });
            }
            actualizarParticipante({
                _id: participante._id,
                puntos: puntosTotales,
                puntos_pick_piloto: puntosTotalesPiloto,
                puntos_pick_constructor: puntosTotalesConstructor
            });
        })

        participantesInscritosWomen.map(participante => {

            let puntosTotales,
                puntosTotalesPiloto,
                puntosTotalesConstructor;

            if (!participante.puntos) {
                puntosTotales = 0;
            } else {
                puntosTotales = parseFloat(participante.puntos);
            }

            if (!participante.puntos_pick_piloto) {
                puntosTotalesPiloto = 0;
            } else {
                puntosTotalesPiloto = parseFloat(participante.puntos_pick_piloto);
            }

            if (!participante.puntos_pick_constructor) {
                puntosTotalesConstructor = 0;
            } else {
                puntosTotalesConstructor = parseFloat(participante.puntos_pick_constructor);
            }

            if (selectedOptionPilotOne === participante.pick_piloto_24 || selectedOptionPilotOne === participante.pick_piloto_24 + '*' || selectedOptionPilotOne === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p1)
                });
                puntosTotales = puntosTotales + parseInt(p1);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p1);
            }
            
            if (selectedOptionPilotOne === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p1) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p1) / 2);
            }
            if (selectedOptionPilotTwo === participante.pick_piloto_24 || selectedOptionPilotTwo === participante.pick_piloto_24 + '*' || selectedOptionPilotTwo === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p2)
                });
                puntosTotales = puntosTotales + parseInt(p2);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p2);
            }
            if (selectedOptionPilotTwo === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p2) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p2) / 2);
            }
            if (selectedOptionPilotThree === participante.pick_piloto_24 || selectedOptionPilotThree === participante.pick_piloto_24 + '*' || selectedOptionPilotThree === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p3)
                });
                puntosTotales = puntosTotales + parseInt(p3);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p3);
            }
            if (selectedOptionPilotThree === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p3) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p3) / 2);
            }
            if (selectedOptionPilotFour === participante.pick_piloto_24 || selectedOptionPilotFour === participante.pick_piloto_24 + '*' || selectedOptionPilotFour === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p4)
                });
                puntosTotales = puntosTotales + parseInt(p4);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p4);
            }
            if (selectedOptionPilotFour === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p4) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p4) / 2);
            }
            if (selectedOptionPilotFive === participante.pick_piloto_24 || selectedOptionPilotFive === participante.pick_piloto_24 + '*' || selectedOptionPilotFive === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p5)
                });
                puntosTotales = puntosTotales + parseInt(p5);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p5);
            }
            if (selectedOptionPilotFive === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p5) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p5) / 2);
            }
            if (selectedOptionPilotSix === participante.pick_piloto_24 || selectedOptionPilotSix === participante.pick_piloto_24 + '*' || selectedOptionPilotSix === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p6)
                });
                puntosTotales = puntosTotales + parseInt(p6);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p6);
            }
            if (selectedOptionPilotSix === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p6) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p6) / 2);
            }
            if (selectedOptionPilotSeven === participante.pick_piloto_24 || selectedOptionPilotSeven === participante.pick_piloto_24 + '*' || selectedOptionPilotSeven === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p7)
                });
                puntosTotales = puntosTotales + parseInt(p7);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p7);
            }
            if (selectedOptionPilotSeven === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p7) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p7) / 2);
            }
            if (selectedOptionPilotEight === participante.pick_piloto_24 || selectedOptionPilotEight === participante.pick_piloto_24 + '*' || selectedOptionPilotEight === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p8)
                });
                puntosTotales = puntosTotales + parseInt(p8);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p8);
            }
            if (selectedOptionPilotEight === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p8) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p8) / 2);
            }
            if (selectedOptionPilotNine === participante.pick_piloto_24 || selectedOptionPilotNine === participante.pick_piloto_24 + '*' || selectedOptionPilotNine === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p9)
                });
                puntosTotales = puntosTotales + parseInt(p9);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p9);
            }
            if (selectedOptionPilotNine === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p9) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p9) / 2);
            }
            if (selectedOptionPilotTen === participante.pick_piloto_24 || selectedOptionPilotTen === participante.pick_piloto_24 + '*' || selectedOptionPilotTen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p10)
                });
                puntosTotales = puntosTotales + parseInt(p10);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p10);
            }
            if (selectedOptionPilotTen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p10) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p10) / 2);
            }
            if (selectedOptionPilotEleven === participante.pick_piloto_24 || selectedOptionPilotEleven === participante.pick_piloto_24 + '*' || selectedOptionPilotEleven === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p11)
                });
                puntosTotales = puntosTotales + parseInt(p11);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p11);
            }
            if (selectedOptionPilotEleven === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p11) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p11) / 2);
            }
            if (selectedOptionPilotTwelve === participante.pick_piloto_24 || selectedOptionPilotTwelve === participante.pick_piloto_24 + '*' || selectedOptionPilotTwelve === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p12)
                });
                puntosTotales = puntosTotales + parseInt(p12);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p12);
            }
            if (selectedOptionPilotTwelve === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p12) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p12) / 2);
            }
            if (selectedOptionPilotThirteen === participante.pick_piloto_24 || selectedOptionPilotThirteen === participante.pick_piloto_24 + '*' || selectedOptionPilotThirteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p13)
                });
                puntosTotales = puntosTotales + parseInt(p13);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p13);
            }
            if (selectedOptionPilotThirteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p13) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p13) / 2);
            }
            if (selectedOptionPilotForteen === participante.pick_piloto_24 || selectedOptionPilotForteen === participante.pick_piloto_24 + '*' || selectedOptionPilotForteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p14)
                });
                puntosTotales = puntosTotales + parseInt(p14);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p14);
            }
            if (selectedOptionPilotForteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p14) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p14) / 2);
            }
            if (selectedOptionPilotFifteen === participante.pick_piloto_24 || selectedOptionPilotFifteen === participante.pick_piloto_24 + '*' || selectedOptionPilotFifteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p15)
                });
                puntosTotales = puntosTotales + parseInt(p15);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p15);
            }
            if (selectedOptionPilotFifteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p15) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p15) / 2);
            }
            if (selectedOptionPilotSixteen === participante.pick_piloto_24 || selectedOptionPilotSixteen === participante.pick_piloto_24 + '*' || selectedOptionPilotSixteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p16)
                });
                puntosTotales = puntosTotales + parseInt(p16);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p16);
            }
            if (selectedOptionPilotSixteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p16) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p16) / 2);
            }
            if (selectedOptionPilotSeventeen === participante.pick_piloto_24 || selectedOptionPilotSeventeen === participante.pick_piloto_24 + '*' || selectedOptionPilotSeventeen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p17)
                });
                puntosTotales = puntosTotales + parseInt(p17);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p17);
            }
            if (selectedOptionPilotSeventeen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p17) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p17) / 2);
            }
            if (selectedOptionPilotEighteen === participante.pick_piloto_24 || selectedOptionPilotEighteen === participante.pick_piloto_24 + '*' || selectedOptionPilotEighteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p18)
                });
                puntosTotales = puntosTotales + parseInt(p18);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p18);
            }
            if (selectedOptionPilotEighteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p18) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p18) / 2);
            }
            if (selectedOptionPilotNineteen === participante.pick_piloto_24 || selectedOptionPilotNineteen === participante.pick_piloto_24 + '*' || selectedOptionPilotNineteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p19)
                });
                puntosTotales = puntosTotales + parseInt(p19);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p19);
            }
            if (selectedOptionPilotNineteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p19) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p19) / 2);
            }
            if (selectedOptionPilotTwenty === participante.pick_piloto_24 || selectedOptionPilotTwenty === participante.pick_piloto_24 + '*' || selectedOptionPilotTwenty === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p20)
                });
                puntosTotales = puntosTotales + parseInt(p20);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p20);
            }
            if (selectedOptionPilotTwenty === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p20) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p20) / 2);
            }

            if (selectedOption === participante.pick_pregunta1_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta1_24: 20
                });
                puntosTotales = puntosTotales + 20;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta1_24: 0
                });
            }

            if (selectedOptionPilotDhl === participante.pick_pregunta2_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta2_24: 10
                });
                puntosTotales = puntosTotales + 10;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta2_24: 0
                });
            }

            if (selectedOptionPilotDnf === participante.pick_pregunta3_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta3_24: 20
                });
                puntosTotales = puntosTotales + 20;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta3_24: 0
                });
            }

            if (participante.pick_constructor_24 === 'Red Bull Racing' || participante.pick_constructor_24 === 'Red Bull Racing*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosRedBull
                });
                puntosTotales = puntosTotales + puntosRedBull;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRedBull;
            }

            if (participante.sorteo_constructor_24 === 'Red Bull Racing') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosRedBull / 2)
                });
                puntosTotales = puntosTotales + (puntosRedBull / 2);
            }

            if (participante.pick_constructor_24 === 'Mercedes' || participante.pick_constructor_24 === 'Mercedes*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMercedes
                });
                puntosTotales = puntosTotales + puntosMercedes;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMercedes;
            }

            if (participante.sorteo_constructor_24 === 'Mercedes') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosMercedes / 2)
                });
                puntosTotales = puntosTotales + (puntosMercedes / 2);
            }

            if (participante.pick_constructor_24 === 'Ferrari' || participante.pick_constructor_24 === 'Ferrari*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosFerrari
                });
                puntosTotales = puntosTotales + puntosFerrari;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosFerrari;
            }

            if (participante.sorteo_constructor_24 === 'Ferrari') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosFerrari / 2)
                });
                puntosTotales = puntosTotales + (puntosFerrari / 2);
            }

            if (participante.pick_constructor_24 === 'McLaren' || participante.pick_constructor_24 === 'McLaren*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMcLaren
                });
                puntosTotales = puntosTotales + puntosMcLaren;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMcLaren;
            }

            if (participante.sorteo_constructor_24 === 'McLaren') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosMcLaren / 2)
                });
                puntosTotales = puntosTotales + (puntosMcLaren / 2);
            }

            if (participante.pick_constructor_24 === 'Alpine' || participante.pick_constructor_24 === 'Alpine*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlpine
                });
                puntosTotales = puntosTotales + puntosAlpine;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlpine;
            }

            if (participante.sorteo_constructor_24 === 'Alpine') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlpine / 2)
                });
                puntosTotales = puntosTotales + (puntosAlpine / 2);
            }

            if (participante.pick_constructor_24 === 'RB' || participante.pick_constructor_24 === 'RB*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlphaTauri
                });
                puntosTotales = puntosTotales + puntosAlphaTauri;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlphaTauri;
            }

            if (participante.sorteo_constructor_24 === 'RB') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlphaTauri / 2)
                });
                puntosTotales = puntosTotales + (puntosAlphaTauri / 2);
            }

            if (participante.pick_constructor_24 === 'Aston Martin' || participante.pick_constructor_24 === 'Aston Martin*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAstonMartin
                });
                puntosTotales = puntosTotales + puntosAstonMartin;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAstonMartin;
            }

            if (participante.sorteo_constructor_24 === 'Aston Martin') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAstonMartin / 2)
                });
                puntosTotales = puntosTotales + (puntosAstonMartin / 2);
            }

            if (participante.pick_constructor_24 === 'Williams' || participante.pick_constructor_24 === 'Williams*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosWilliams
                });
                puntosTotales = puntosTotales + puntosWilliams;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosWilliams;
            }

            if (participante.sorteo_constructor_24 === 'Williams') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosWilliams / 2)
                });
                puntosTotales = puntosTotales + (puntosWilliams / 2);
            }

            if (participante.pick_constructor_24 === 'Kick Sauber' || participante.pick_constructor_24 === 'Kick Sauber*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlfaRomeo
                });
                puntosTotales = puntosTotales + puntosAlfaRomeo;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlfaRomeo;
            }

            if (participante.sorteo_constructor_24 === 'Kick Sauber') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlfaRomeo / 2)
                });
                puntosTotales = puntosTotales + (puntosAlfaRomeo / 2);
            }

            if (participante.pick_constructor_24 === 'Haas F1 Team' || participante.pick_constructor_24 === 'Haas F1 Team*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosHaas
                });
                puntosTotales = puntosTotales + puntosHaas;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosHaas;
            }

            if (participante.sorteo_constructor_24 === 'Haas F1 Team') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosHaas / 2)
                });
                puntosTotales = puntosTotales + (puntosHaas / 2);
            }

            if (participante.pick_constructor_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: 0
                });
            }

            if (participante.pick_piloto_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: 0
                });
            }
            actualizarParticipante({
                _id: participante._id,
                puntos: puntosTotales,
                puntos_pick_piloto: puntosTotalesPiloto,
                puntos_pick_constructor: puntosTotalesConstructor
            });
        })

        participantesInscritosWarm.map(participante => {

            let puntosTotales,
                puntosTotalesPiloto,
                puntosTotalesConstructor;

            if (!participante.puntos) {
                puntosTotales = 0;
            } else {
                puntosTotales = parseFloat(participante.puntos);
            }

            if (!participante.puntos_pick_piloto) {
                puntosTotalesPiloto = 0;
            } else {
                puntosTotalesPiloto = parseFloat(participante.puntos_pick_piloto);
            }

            if (!participante.puntos_pick_constructor) {
                puntosTotalesConstructor = 0;
            } else {
                puntosTotalesConstructor = parseFloat(participante.puntos_pick_constructor);
            }

            if (selectedOptionPilotOne === participante.pick_piloto_24 || selectedOptionPilotOne === participante.pick_piloto_24 + '*' || selectedOptionPilotOne === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p1)
                });
                puntosTotales = puntosTotales + parseInt(p1);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p1);
            }
            
            if (selectedOptionPilotOne === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p1) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p1) / 2);
            }
            if (selectedOptionPilotTwo === participante.pick_piloto_24 || selectedOptionPilotTwo === participante.pick_piloto_24 + '*' || selectedOptionPilotTwo === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p2)
                });
                puntosTotales = puntosTotales + parseInt(p2);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p2);
            }
            if (selectedOptionPilotTwo === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p2) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p2) / 2);
            }
            if (selectedOptionPilotThree === participante.pick_piloto_24 || selectedOptionPilotThree === participante.pick_piloto_24 + '*' || selectedOptionPilotThree === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p3)
                });
                puntosTotales = puntosTotales + parseInt(p3);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p3);
            }
            if (selectedOptionPilotThree === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p3) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p3) / 2);
            }
            if (selectedOptionPilotFour === participante.pick_piloto_24 || selectedOptionPilotFour === participante.pick_piloto_24 + '*' || selectedOptionPilotFour === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p4)
                });
                puntosTotales = puntosTotales + parseInt(p4);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p4);
            }
            if (selectedOptionPilotFour === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p4) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p4) / 2);
            }
            if (selectedOptionPilotFive === participante.pick_piloto_24 || selectedOptionPilotFive === participante.pick_piloto_24 + '*' || selectedOptionPilotFive === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p5)
                });
                puntosTotales = puntosTotales + parseInt(p5);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p5);
            }
            if (selectedOptionPilotFive === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p5) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p5) / 2);
            }
            if (selectedOptionPilotSix === participante.pick_piloto_24 || selectedOptionPilotSix === participante.pick_piloto_24 + '*' || selectedOptionPilotSix === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p6)
                });
                puntosTotales = puntosTotales + parseInt(p6);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p6);
            }
            if (selectedOptionPilotSix === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p6) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p6) / 2);
            }
            if (selectedOptionPilotSeven === participante.pick_piloto_24 || selectedOptionPilotSeven === participante.pick_piloto_24 + '*' || selectedOptionPilotSeven === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p7)
                });
                puntosTotales = puntosTotales + parseInt(p7);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p7);
            }
            if (selectedOptionPilotSeven === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p7) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p7) / 2);
            }
            if (selectedOptionPilotEight === participante.pick_piloto_24 || selectedOptionPilotEight === participante.pick_piloto_24 + '*' || selectedOptionPilotEight === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p8)
                });
                puntosTotales = puntosTotales + parseInt(p8);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p8);
            }
            if (selectedOptionPilotEight === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p8) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p8) / 2);
            }
            if (selectedOptionPilotNine === participante.pick_piloto_24 || selectedOptionPilotNine === participante.pick_piloto_24 + '*' || selectedOptionPilotNine === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p9)
                });
                puntosTotales = puntosTotales + parseInt(p9);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p9);
            }
            if (selectedOptionPilotNine === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p9) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p9) / 2);
            }
            if (selectedOptionPilotTen === participante.pick_piloto_24 || selectedOptionPilotTen === participante.pick_piloto_24 + '*' || selectedOptionPilotTen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p10)
                });
                puntosTotales = puntosTotales + parseInt(p10);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p10);
            }
            if (selectedOptionPilotTen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p10) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p10) / 2);
            }
            if (selectedOptionPilotEleven === participante.pick_piloto_24 || selectedOptionPilotEleven === participante.pick_piloto_24 + '*' || selectedOptionPilotEleven === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p11)
                });
                puntosTotales = puntosTotales + parseInt(p11);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p11);
            }
            if (selectedOptionPilotEleven === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p11) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p11) / 2);
            }
            if (selectedOptionPilotTwelve === participante.pick_piloto_24 || selectedOptionPilotTwelve === participante.pick_piloto_24 + '*' || selectedOptionPilotTwelve === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p12)
                });
                puntosTotales = puntosTotales + parseInt(p12);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p12);
            }
            if (selectedOptionPilotTwelve === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p12) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p12) / 2);
            }
            if (selectedOptionPilotThirteen === participante.pick_piloto_24 || selectedOptionPilotThirteen === participante.pick_piloto_24 + '*' || selectedOptionPilotThirteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p13)
                });
                puntosTotales = puntosTotales + parseInt(p13);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p13);
            }
            if (selectedOptionPilotThirteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p13) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p13) / 2);
            }
            if (selectedOptionPilotForteen === participante.pick_piloto_24 || selectedOptionPilotForteen === participante.pick_piloto_24 + '*' || selectedOptionPilotForteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p14)
                });
                puntosTotales = puntosTotales + parseInt(p14);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p14);
            }
            if (selectedOptionPilotForteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p14) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p14) / 2);
            }
            if (selectedOptionPilotFifteen === participante.pick_piloto_24 || selectedOptionPilotFifteen === participante.pick_piloto_24 + '*' || selectedOptionPilotFifteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p15)
                });
                puntosTotales = puntosTotales + parseInt(p15);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p15);
            }
            if (selectedOptionPilotFifteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p15) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p15) / 2);
            }
            if (selectedOptionPilotSixteen === participante.pick_piloto_24 || selectedOptionPilotSixteen === participante.pick_piloto_24 + '*' || selectedOptionPilotSixteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p16)
                });
                puntosTotales = puntosTotales + parseInt(p16);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p16);
            }
            if (selectedOptionPilotSixteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p16) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p16) / 2);
            }
            if (selectedOptionPilotSeventeen === participante.pick_piloto_24 || selectedOptionPilotSeventeen === participante.pick_piloto_24 + '*' || selectedOptionPilotSeventeen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p17)
                });
                puntosTotales = puntosTotales + parseInt(p17);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p17);
            }
            if (selectedOptionPilotSeventeen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p17) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p17) / 2);
            }
            if (selectedOptionPilotEighteen === participante.pick_piloto_24 || selectedOptionPilotEighteen === participante.pick_piloto_24 + '*' || selectedOptionPilotEighteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p18)
                });
                puntosTotales = puntosTotales + parseInt(p18);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p18);
            }
            if (selectedOptionPilotEighteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p18) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p18) / 2);
            }
            if (selectedOptionPilotNineteen === participante.pick_piloto_24 || selectedOptionPilotNineteen === participante.pick_piloto_24 + '*' || selectedOptionPilotNineteen === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p19)
                });
                puntosTotales = puntosTotales + parseInt(p19);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p19);
            }
            if (selectedOptionPilotNineteen === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p19) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p19) / 2);
            }
            if (selectedOptionPilotTwenty === participante.pick_piloto_24 || selectedOptionPilotTwenty === participante.pick_piloto_24 + '*' || selectedOptionPilotTwenty === participante.pick_piloto_24 + '.') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p20)
                });
                puntosTotales = puntosTotales + parseInt(p20);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p20);
            }
            if (selectedOptionPilotTwenty === participante.sorteo_piloto_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_piloto_24: (parseInt(p20) / 2)
                });
                puntosTotales = puntosTotales + (parseInt(p20) / 2);
            }

            if (selectedOption === participante.pick_pregunta1_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta1_24: 20
                });
                puntosTotales = puntosTotales + 20;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta1_24: 0
                });
            }

            if (selectedOptionPilotDhl === participante.pick_pregunta2_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta2_24: 10
                });
                puntosTotales = puntosTotales + 10;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta2_24: 0
                });
            }

            if (selectedOptionPilotDnf === participante.pick_pregunta3_24) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta3_24: 20
                });
                puntosTotales = puntosTotales + 20;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pregunta3_24: 0
                });
            }

            if (participante.pick_constructor_24 === 'Red Bull Racing' || participante.pick_constructor_24 === 'Red Bull Racing*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosRedBull
                });
                puntosTotales = puntosTotales + puntosRedBull;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRedBull;
            }

            if (participante.sorteo_constructor_24 === 'Red Bull Racing') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosRedBull / 2)
                });
                puntosTotales = puntosTotales + (puntosRedBull / 2);
            }

            if (participante.pick_constructor_24 === 'Mercedes' || participante.pick_constructor_24 === 'Mercedes*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMercedes
                });
                puntosTotales = puntosTotales + puntosMercedes;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMercedes;
            }

            if (participante.sorteo_constructor_24 === 'Mercedes') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosMercedes / 2)
                });
                puntosTotales = puntosTotales + (puntosMercedes / 2);
            }

            if (participante.pick_constructor_24 === 'Ferrari' || participante.pick_constructor_24 === 'Ferrari*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosFerrari
                });
                puntosTotales = puntosTotales + puntosFerrari;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosFerrari;
            }

            if (participante.sorteo_constructor_24 === 'Ferrari') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosFerrari / 2)
                });
                puntosTotales = puntosTotales + (puntosFerrari / 2);
            }

            if (participante.pick_constructor_24 === 'McLaren' || participante.pick_constructor_24 === 'McLaren*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMcLaren
                });
                puntosTotales = puntosTotales + puntosMcLaren;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMcLaren;
            }

            if (participante.sorteo_constructor_24 === 'McLaren') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosMcLaren / 2)
                });
                puntosTotales = puntosTotales + (puntosMcLaren / 2);
            }

            if (participante.pick_constructor_24 === 'Alpine' || participante.pick_constructor_24 === 'Alpine*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlpine
                });
                puntosTotales = puntosTotales + puntosAlpine;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlpine;
            }

            if (participante.sorteo_constructor_24 === 'Alpine') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlpine / 2)
                });
                puntosTotales = puntosTotales + (puntosAlpine / 2);
            }

            if (participante.pick_constructor_24 === 'RB' || participante.pick_constructor_24 === 'RB*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlphaTauri
                });
                puntosTotales = puntosTotales + puntosAlphaTauri;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlphaTauri;
            }

            if (participante.sorteo_constructor_24 === 'RB') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlphaTauri / 2)
                });
                puntosTotales = puntosTotales + (puntosAlphaTauri / 2);
            }

            if (participante.pick_constructor_24 === 'Aston Martin' || participante.pick_constructor_24 === 'Aston Martin*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAstonMartin
                });
                puntosTotales = puntosTotales + puntosAstonMartin;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAstonMartin;
            }

            if (participante.sorteo_constructor_24 === 'Aston Martin') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAstonMartin / 2)
                });
                puntosTotales = puntosTotales + (puntosAstonMartin / 2);
            }

            if (participante.pick_constructor_24 === 'Williams' || participante.pick_constructor_24 === 'Williams*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosWilliams
                });
                puntosTotales = puntosTotales + puntosWilliams;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosWilliams;
            }

            if (participante.sorteo_constructor_24 === 'Williams') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosWilliams / 2)
                });
                puntosTotales = puntosTotales + (puntosWilliams / 2);
            }

            if (participante.pick_constructor_24 === 'Kick Sauber' || participante.pick_constructor_24 === 'Kick Sauber*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlfaRomeo
                });
                puntosTotales = puntosTotales + puntosAlfaRomeo;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlfaRomeo;
            }

            if (participante.sorteo_constructor_24 === 'Kick Sauber') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosAlfaRomeo / 2)
                });
                puntosTotales = puntosTotales + (puntosAlfaRomeo / 2);
            }

            if (participante.pick_constructor_24 === 'Haas F1 Team' || participante.pick_constructor_24 === 'Haas F1 Team*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosHaas
                });
                puntosTotales = puntosTotales + puntosHaas;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosHaas;
            }

            if (participante.sorteo_constructor_24 === 'Haas F1 Team') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_sorteo_constructor_24: (puntosHaas / 2)
                });
                puntosTotales = puntosTotales + (puntosHaas / 2);
            }

            if (participante.pick_constructor_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: 0
                });
            }

            if (participante.pick_piloto_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: 0
                });
            }
            actualizarParticipante({
                _id: participante._id,
                puntos: puntosTotales,
                puntos_pick_piloto: puntosTotalesPiloto,
                puntos_pick_constructor: puntosTotalesConstructor
            });
        })

        participantesInscritosPaddock.map(participante => {

            let puntosTotales,
                puntosTotalesPiloto,
                puntosTotalesConstructor;

            if (!participante.puntos) {
                puntosTotales = 0;
            } else {
                puntosTotales = parseFloat(participante.puntos);
            }

            if (!participante.puntos_pick_piloto) {
                puntosTotalesPiloto = 0;
            } else {
                puntosTotalesPiloto = parseFloat(participante.puntos_pick_piloto);
            }

            if (!participante.puntos_pick_constructor) {
                puntosTotalesConstructor = 0;
            } else {
                puntosTotalesConstructor = parseFloat(participante.puntos_pick_constructor);
            }

            if (selectedOptionPilotOne === participante.pick_piloto_24 || selectedOptionPilotOne === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p1)
                });
                puntosTotales = puntosTotales + parseInt(p1);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p1);
            }
            if (selectedOptionPilotTwo === participante.pick_piloto_24 || selectedOptionPilotTwo === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p2)
                });
                puntosTotales = puntosTotales + parseInt(p2);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p2);
            }
            if (selectedOptionPilotThree === participante.pick_piloto_24 || selectedOptionPilotThree === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p3)
                });
                puntosTotales = puntosTotales + parseInt(p3);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p3);
            }
            if (selectedOptionPilotFour === participante.pick_piloto_24 || selectedOptionPilotFour === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p4)
                });
                puntosTotales = puntosTotales + parseInt(p4);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p4);
            }
            if (selectedOptionPilotFive === participante.pick_piloto_24 || selectedOptionPilotFive === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p5)
                });
                puntosTotales = puntosTotales + parseInt(p5);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p5);
            }
            if (selectedOptionPilotSix === participante.pick_piloto_24 || selectedOptionPilotSix === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p6)
                });
                puntosTotales = puntosTotales + parseInt(p6);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p6);
            }
            if (selectedOptionPilotSeven === participante.pick_piloto_24 || selectedOptionPilotSeven === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p7)
                });
                puntosTotales = puntosTotales + parseInt(p7);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p7);
            }
            if (selectedOptionPilotEight === participante.pick_piloto_24 || selectedOptionPilotEight === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p8)
                });
                puntosTotales = puntosTotales + parseInt(p8);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p8);
            }
            if (selectedOptionPilotNine === participante.pick_piloto_24 || selectedOptionPilotNine === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p9)
                });
                puntosTotales = puntosTotales + parseInt(p9);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p9);
            }
            if (selectedOptionPilotTen === participante.pick_piloto_24 || selectedOptionPilotTen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p10)
                });
                puntosTotales = puntosTotales + parseInt(p10);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p10);
            }
            if (selectedOptionPilotEleven === participante.pick_piloto_24 || selectedOptionPilotEleven === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p11)
                });
                puntosTotales = puntosTotales + parseInt(p11);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p11);
            }
            if (selectedOptionPilotTwelve === participante.pick_piloto_24 || selectedOptionPilotTwelve === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p12)
                });
                puntosTotales = puntosTotales + parseInt(p12);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p12);
            }
            if (selectedOptionPilotThirteen === participante.pick_piloto_24 || selectedOptionPilotThirteen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p13)
                });
                puntosTotales = puntosTotales + parseInt(p13);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p13);
            }
            if (selectedOptionPilotForteen === participante.pick_piloto_24 || selectedOptionPilotForteen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p14)
                });
                puntosTotales = puntosTotales + parseInt(p14);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p14);
            }
            if (selectedOptionPilotFifteen === participante.pick_piloto_24 || selectedOptionPilotFifteen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p15)
                });
                puntosTotales = puntosTotales + parseInt(p15);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p15);
            }
            if (selectedOptionPilotSixteen === participante.pick_piloto_24 || selectedOptionPilotSixteen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p16)
                });
                puntosTotales = puntosTotales + parseInt(p16);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p16);
            }
            if (selectedOptionPilotSeventeen === participante.pick_piloto_24 || selectedOptionPilotSeventeen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p17)
                });
                puntosTotales = puntosTotales + parseInt(p17);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p17);
            }
            if (selectedOptionPilotEighteen === participante.pick_piloto_24 || selectedOptionPilotEighteen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p18)
                });
                puntosTotales = puntosTotales + parseInt(p18);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p18);
            }
            if (selectedOptionPilotNineteen === participante.pick_piloto_24 || selectedOptionPilotNineteen === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p19)
                });
                puntosTotales = puntosTotales + parseInt(p19);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p19);
            }
            if (selectedOptionPilotTwenty === participante.pick_piloto_24 || selectedOptionPilotTwenty === participante.pick_piloto_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: parseInt(p20)
                });
                puntosTotales = puntosTotales + parseInt(p20);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p20);
            }

            if (selectedOptionPilotOne === participante.pick_posicion1_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion1_24: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion1_24: 0
                });
            }

            if (selectedOptionPilotTwo === participante.pick_posicion2_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion2_24: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion2_24: 0
                });
            }

            if (selectedOptionPilotThree === participante.pick_posicion3_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion3_24: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion3_24: 0
                });
            }

            if (selectedOptionPilotFour === participante.pick_posicion4_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion4_24: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion4_24: 0
                });
            }

            if (selectedOptionPilotFive === participante.pick_posicion5_24 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion5_24: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion5_24: 0
                });
            }

            if (participante.pick_constructor_24 === 'Red Bull Racing' || participante.pick_constructor_24 === 'Red Bull Racing*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosRedBull
                });
                puntosTotales = puntosTotales + puntosRedBull;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRedBull;
            }

            if (participante.pick_constructor_24 === 'Mercedes' || participante.pick_constructor_24 === 'Mercedes*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMercedes
                });
                puntosTotales = puntosTotales + puntosMercedes;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMercedes;
            }

            if (participante.pick_constructor_24 === 'Ferrari' || participante.pick_constructor_24 === 'Ferrari*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosFerrari
                });
                puntosTotales = puntosTotales + puntosFerrari;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosFerrari;
            }

            if (participante.pick_constructor_24 === 'McLaren' || participante.pick_constructor_24 === 'McLaren*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosMcLaren
                });
                puntosTotales = puntosTotales + puntosMcLaren;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMcLaren;
            }

            if (participante.pick_constructor_24 === 'Alpine' || participante.pick_constructor_24 === 'Alpine*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlpine
                });
                puntosTotales = puntosTotales + puntosAlpine;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlpine;
            }

            if (participante.pick_constructor_24 === 'RB' || participante.pick_constructor_24 === 'RB*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlphaTauri
                });
                puntosTotales = puntosTotales + puntosAlphaTauri;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlphaTauri;
            }

            if (participante.pick_constructor_24 === 'Aston Martin' || participante.pick_constructor_24 === 'Aston Martin*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAstonMartin
                });
                puntosTotales = puntosTotales + puntosAstonMartin;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAstonMartin;
            }

            if (participante.pick_constructor_24 === 'Williams' || participante.pick_constructor_24 === 'Williams*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosWilliams
                });
                puntosTotales = puntosTotales + puntosWilliams;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosWilliams;
            }

            if (participante.pick_constructor_24 === 'Kick Sauber' || participante.pick_constructor_24 === 'Kick Sauber*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosAlfaRomeo
                });
                puntosTotales = puntosTotales + puntosAlfaRomeo;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlfaRomeo;
            }

            if (participante.pick_constructor_24 === 'Haas F1 Team' || participante.pick_constructor_24 === 'Haas F1 Team*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: puntosHaas
                });
                puntosTotales = puntosTotales + puntosHaas;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosHaas;
            }

            if (participante.pick_constructor_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_24: 0
                });
            }

            if (participante.pick_piloto_24 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_24: 0
                });
            }
            actualizarParticipante({
                _id: participante._id,
                puntos: puntosTotales,
                puntos_pick_piloto: puntosTotalesPiloto,
                puntos_pick_constructor: puntosTotalesConstructor
            });
        })
    }

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <MainNavigation/>
            <section className='block strategy-results'>
                <h1>{index} {currentRace.pais} - {currentRace.ciudad}</h1>
                <h2>Resultados de la carrera</h2>
                <div className='table'>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='table-header'>POS. GEN.</div>
                        </div>
                        <div className='pilot-field'>
                            <div className='table-header'>PILOTO</div>
                        </div>
                        <div className='points-field'>
                            <div className='table-header'>PUNTOS</div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P1</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotOne}>
                                    <span className='text'>{selectedOptionPilotOne || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotOne && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotOne(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                name='p1'
                                min='0'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P2</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTwo}>
                                    <span className='text'>{selectedOptionPilotTwo || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTwo && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTwo(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p2'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P3</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotThree}>
                                    <span className='text'>{selectedOptionPilotThree || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotThree && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotThree(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p3'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P4</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotFour}>
                                    <span className='text'>{selectedOptionPilotFour || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotFour && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotFour(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p4'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P5</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotFive}>
                                    <span className='text'>{selectedOptionPilotFive || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotFive && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotFive(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p5'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P6</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSix}>
                                    <span className='text'>{selectedOptionPilotSix || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSix && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSix(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p6'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P7</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSeven}>
                                    <span className='text'>{selectedOptionPilotSeven || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSeven && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSeven(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p7'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P8</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotEight}>
                                    <span className='text'>{selectedOptionPilotEight || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotEight && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotEight(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p8'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P9</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotNine}>
                                    <span className='text'>{selectedOptionPilotNine || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotNine && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotNine(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p9'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P10</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTen}>
                                    <span className='text'>{selectedOptionPilotTen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p10'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P11</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotEleven}>
                                    <span className='text'>{selectedOptionPilotEleven || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotEleven && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotEleven(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p11'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P12</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTwelve}>
                                    <span className='text'>{selectedOptionPilotTwelve || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTwelve && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTwelve(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p12'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P13</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotThirteen}>
                                    <span className='text'>{selectedOptionPilotThirteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotThirteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotThirteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p13'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P14</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotForteen}>
                                    <span className='text'>{selectedOptionPilotForteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotForteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotForteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p14'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P15</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotFifteen}>
                                    <span className='text'>{selectedOptionPilotFifteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotFifteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotFifteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p15'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P16</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSixteen}>
                                    <span className='text'>{selectedOptionPilotSixteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSixteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSixteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p16'
                                type="number"
                                onChange={onChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P17</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotSeventeen}>
                                    <span className='text'>{selectedOptionPilotSeventeen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotSeventeen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotSeventeen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p17'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P18</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotEighteen}>
                                    <span className='text'>{selectedOptionPilotEighteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotEighteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotEighteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p18'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P19</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotNineteen}>
                                    <span className='text'>{selectedOptionPilotNineteen || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotNineteen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotNineteen(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p19'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P20</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotTwenty}>
                                    <span className='text'>{selectedOptionPilotTwenty || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotTwenty && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li
                                                    onClick={
                                                        onOptionClickedPilotTwenty(piloto.nombre + ' ' + piloto.apellido + '*')
                                                    }
                                                    key={Math.random()}
                                                >
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p20'
                                type="number"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'></div>
                        </div>
                        <div className='pilot-field'>Puntos totales:</div>
                        <div className='points-field'>
                            {
                                (parseInt(p1) >= 0 && parseInt(p2) >= 0 && parseInt(p3) >= 0 && parseInt(p4) >= 0 && parseInt(p5) >= 0 && parseInt(p6) >= 0 && parseInt(p7) >= 0 && parseInt(p8) >= 0 && parseInt(p9) >= 0 && parseInt(p10) >= 0 && parseInt(p11) >= 0 && parseInt(p12) >= 0 && parseInt(p13) >= 0 && parseInt(p14) >= 0 && parseInt(p15) >= 0 && parseInt(p16) >= 0 && parseInt(p17) >= 0 && parseInt(p18) >= 0 && parseInt(p19) >= 0 && parseInt(p20) >= 0)
                                    ?
                                        parseInt(p1) + parseInt(p2) + parseInt(p3) + parseInt(p4) + parseInt(p5) + parseInt(p6) + parseInt(p7) + parseInt(p8) + parseInt(p9) + parseInt(p10) + parseInt(p11) + parseInt(p12) + parseInt(p13) + parseInt(p14) + parseInt(p15) + parseInt(p16) + parseInt(p17) + parseInt(p18) + parseInt(p19) + parseInt(p20)
                                    :
                                        null
                            } pts
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>Checo</div>
                        </div>
                        <div className='pilot-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={toggling}>
                                    <span className='text'>{selectedOption || "Elige tu respuesta"}</span>
                                </div>
                                {isOpen && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {options.map(option => (
                                                <li onClick={onOptionClicked(option)} key={Math.random()}>
                                                {option}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'></div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>DHL</div>
                        </div>
                        <div className='pilot-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotDhl}>
                                    <span className='text'>{selectedOptionPilotDhl || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotDhl && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li onClick={onOptionClickedPilotDhl(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'></div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>DNF</div>
                        </div>
                        <div className='pilot-field'>
                            <div className='custom-select'>
                                <div className='indication' onClick={togglingPilotDnf}>
                                    <span className='text'>{selectedOptionPilotDnf || "Elige tu respuesta"}</span>
                                </div>
                                {isOpenPilotDnf && (
                                    <div className='options-wrapper'>
                                        <ul className='list-opts'>
                                            {pilotos.map(piloto => (
                                                <li onClick={onOptionClickedPilotDnf(piloto.nombre + ' ' + piloto.apellido)} key={Math.random()}>
                                                    {piloto.nombre + ' ' + piloto.apellido}
                                                </li>
                                            ))}
                                            <li onClick={onOptionClickedPilotDnf('N/A')} key='N/A'>N/A</li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='points-field'></div>
                    </div>
                </div>
                <div
                    to='/resultados-carrera'
                    className='save-btn'
                    onClick={saveInfo}
                >
                    Guardar
                </div>
                <Link to={"/administrador"} className="back">
                    <div className="text">Regresar</div>
                </Link>
            </section>
        </div>
    )
}

export default Estrategia;