import React, {useContext, useEffect} from 'react';
import MainNavigation from '../partials/MainNavigation';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import AuthContext from '../../context/autenticacion/authContext';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Import styles
import backIcon from '../../design/imgs/arrow-l-1.svg';
import '../../design/styles/race-result.scss';

const ResultadoCarrera = () => {

    let concurso = JSON.parse(localStorage.getItem('currentConcurso')),
        carrera = JSON.parse(localStorage.getItem('currentRace')),
        concursoIndex = localStorage.getItem('index');

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {participantesInscritos, obtenerParticipantes} = listaContext;

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    useEffect(() => {
        obtenerParticipantes(concurso._id);
    }, [])

    const descargarPdf = () => {
        const input = document.getElementById('pdf-content');

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const width = pdf.internal.pageSize.getWidth();
                const height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 0, 0, width, height);
                
                pdf.save(concurso.nombre + ' - ' + carrera.pais + ' - ' + carrera.ciudad + '.pdf');
        });
    };

    return (
        <div className='outer-wrapper'>
            <MainNavigation/>

            <section className='block race-result'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>F1 QUINIELA 2024</div>
                            <div className='desc'>{concurso.nombre}</div>
                            {
                                (usuario.email === 'david7_qh@hotmail.com' || usuario.email === 'jdecolombres_11@hotmail.com' || usuario.email === 'alvarordlp@gmail.com')
                                    ?
                                        <button onClick={descargarPdf}>Descargar PDF</button>
                                    :
                                        null
                            }
                        </div>
                        <div className='content'>
                            <div className='gplk-table' id="pdf-content">
                                <div className='gplk-table-header'>
                                    Resultados de la Carrera - {concursoIndex} {carrera.pais}, {carrera.ciudad}
                                </div>
                                <div className='flex-content'>
                                    <div className='user-content'>
                                        <div className='gplk-table-indicator'>Usuario</div>
                                        <div className='flex-content gplk-row main'>
                                            <div className='points'>PTS</div>
                                            <div className='info-value'>NICKNAME</div>
                                        </div>
                                        {participantesInscritos.map(participante => 
                                            <div className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null} key={participante._id}>
                                                <div className='points'>
                                                    
                                                    {
                                                        (concurso.nombre === 'GOAT')
                                                            ?
                                                                (participante.puntos_pick_piloto_01 + participante.puntos_pick_constructor_01 + participante.puntos_posicion1_01 + participante.puntos_posicion2_01 + participante.puntos_posicion3_01 + participante.puntos_posicion4_01 + participante.puntos_posicion5_01 && concursoIndex === '01')
                                                                ? participante.puntos_pick_piloto_01 + participante.puntos_pick_constructor_01 + participante.puntos_posicion1_01 + participante.puntos_posicion2_01 + participante.puntos_posicion3_01 + participante.puntos_posicion4_01 + participante.puntos_posicion5_01
                                                                : (participante.puntos_pick_piloto_02 + participante.puntos_pick_constructor_02 + participante.puntos_posicion1_02 + participante.puntos_posicion2_02 + participante.puntos_posicion3_02 + participante.puntos_posicion4_02 + participante.puntos_posicion5_02 && concursoIndex === '02')
                                                                ? participante.puntos_pick_piloto_02 + participante.puntos_pick_constructor_02 + participante.puntos_posicion1_02 + participante.puntos_posicion2_02 + participante.puntos_posicion3_02 + participante.puntos_posicion4_02 + participante.puntos_posicion5_02
                                                                : (participante.puntos_pick_piloto_03 + participante.puntos_pick_constructor_03 + participante.puntos_posicion1_03 + participante.puntos_posicion2_03 + participante.puntos_posicion3_03 + participante.puntos_posicion4_03 + participante.puntos_posicion5_03 && concursoIndex === '03')
                                                                ? participante.puntos_pick_piloto_03 + participante.puntos_pick_constructor_03 + participante.puntos_posicion1_03 + participante.puntos_posicion2_03 + participante.puntos_posicion3_03 + participante.puntos_posicion4_03 + participante.puntos_posicion5_03
                                                                : (participante.puntos_pick_piloto_04 + participante.puntos_pick_constructor_04 + participante.puntos_posicion1_04 + participante.puntos_posicion2_04 + participante.puntos_posicion3_04 + participante.puntos_posicion4_04 + participante.puntos_posicion5_04 && concursoIndex === '04')
                                                                ? participante.puntos_pick_piloto_04 + participante.puntos_pick_constructor_04 + participante.puntos_posicion1_04 + participante.puntos_posicion2_04 + participante.puntos_posicion3_04 + participante.puntos_posicion4_04 + participante.puntos_posicion5_04
                                                                : (participante.puntos_pick_piloto_05 + participante.puntos_pick_constructor_05 + participante.puntos_posicion1_05 + participante.puntos_posicion2_05 + participante.puntos_posicion3_05 + participante.puntos_posicion4_05 + participante.puntos_posicion5_05 && concursoIndex === '05')
                                                                ? participante.puntos_pick_piloto_05 + participante.puntos_pick_constructor_05 + participante.puntos_posicion1_05 + participante.puntos_posicion2_05 + participante.puntos_posicion3_05 + participante.puntos_posicion4_05 + participante.puntos_posicion5_05
                                                                : (participante.puntos_pick_piloto_06 + participante.puntos_pick_constructor_06 + participante.puntos_posicion1_06 + participante.puntos_posicion2_06 + participante.puntos_posicion3_06 + participante.puntos_posicion4_06 + participante.puntos_posicion5_06 && concursoIndex === '06')
                                                                ? participante.puntos_pick_piloto_06 + participante.puntos_pick_constructor_06 + participante.puntos_posicion1_06 + participante.puntos_posicion2_06 + participante.puntos_posicion3_06 + participante.puntos_posicion4_06 + participante.puntos_posicion5_06
                                                                : (participante.puntos_pick_piloto_07 + participante.puntos_pick_constructor_07 + participante.puntos_posicion1_07 + participante.puntos_posicion2_07 + participante.puntos_posicion3_07 + participante.puntos_posicion4_07 + participante.puntos_posicion5_07 && concursoIndex === '07')
                                                                ? participante.puntos_pick_piloto_07 + participante.puntos_pick_constructor_07 + participante.puntos_posicion1_07 + participante.puntos_posicion2_07 + participante.puntos_posicion3_07 + participante.puntos_posicion4_07 + participante.puntos_posicion5_07
                                                                : (participante.puntos_pick_piloto_08 + participante.puntos_pick_constructor_08 + participante.puntos_posicion1_08 + participante.puntos_posicion2_08 + participante.puntos_posicion3_08 + participante.puntos_posicion4_08 + participante.puntos_posicion5_08 && concursoIndex === '08')
                                                                ? participante.puntos_pick_piloto_08 + participante.puntos_pick_constructor_08 + participante.puntos_posicion1_08 + participante.puntos_posicion2_08 + participante.puntos_posicion3_08 + participante.puntos_posicion4_08 + participante.puntos_posicion5_08
                                                                : (participante.puntos_pick_piloto_09 + participante.puntos_pick_constructor_09 + participante.puntos_posicion1_09 + participante.puntos_posicion2_09 + participante.puntos_posicion3_09 + participante.puntos_posicion4_09 + participante.puntos_posicion5_09 && concursoIndex === '09')
                                                                ? participante.puntos_pick_piloto_09 + participante.puntos_pick_constructor_09 + participante.puntos_posicion1_09 + participante.puntos_posicion2_09 + participante.puntos_posicion3_09 + participante.puntos_posicion4_09 + participante.puntos_posicion5_09
                                                                : (participante.puntos_pick_piloto_10 + participante.puntos_pick_constructor_10 + participante.puntos_posicion1_10 + participante.puntos_posicion2_10 + participante.puntos_posicion3_10 + participante.puntos_posicion4_10 + participante.puntos_posicion5_10 && concursoIndex === '10')
                                                                ? participante.puntos_pick_piloto_10 + participante.puntos_pick_constructor_10 + participante.puntos_posicion1_10 + participante.puntos_posicion2_10 + participante.puntos_posicion3_10 + participante.puntos_posicion4_10 + participante.puntos_posicion5_10
                                                                : (participante.puntos_pick_piloto_11 + participante.puntos_pick_constructor_11 + participante.puntos_posicion1_11 + participante.puntos_posicion2_11 + participante.puntos_posicion3_11 + participante.puntos_posicion4_11 + participante.puntos_posicion5_11 && concursoIndex === '11')
                                                                ? participante.puntos_pick_piloto_11 + participante.puntos_pick_constructor_11 + participante.puntos_posicion1_11 + participante.puntos_posicion2_11 + participante.puntos_posicion3_11 + participante.puntos_posicion4_11 + participante.puntos_posicion5_11
                                                                : (participante.puntos_pick_piloto_12 + participante.puntos_pick_constructor_12 + participante.puntos_posicion1_12 + participante.puntos_posicion2_12 + participante.puntos_posicion3_12 + participante.puntos_posicion4_12 + participante.puntos_posicion5_12 && concursoIndex === '12')
                                                                ? participante.puntos_pick_piloto_12 + participante.puntos_pick_constructor_12 + participante.puntos_posicion1_12 + participante.puntos_posicion2_12 + participante.puntos_posicion3_12 + participante.puntos_posicion4_12 + participante.puntos_posicion5_12
                                                                : (participante.puntos_pick_piloto_13 + participante.puntos_pick_constructor_13 + participante.puntos_posicion1_13 + participante.puntos_posicion2_13 + participante.puntos_posicion3_13 + participante.puntos_posicion4_13 + participante.puntos_posicion5_13 && concursoIndex === '13')
                                                                ? participante.puntos_pick_piloto_13 + participante.puntos_pick_constructor_13 + participante.puntos_posicion1_13 + participante.puntos_posicion2_13 + participante.puntos_posicion3_13 + participante.puntos_posicion4_13 + participante.puntos_posicion5_13
                                                                : (participante.puntos_pick_piloto_14 + participante.puntos_pick_constructor_14 + participante.puntos_posicion1_14 + participante.puntos_posicion2_14 + participante.puntos_posicion3_14 + participante.puntos_posicion4_14 + participante.puntos_posicion5_14 && concursoIndex === '14')
                                                                ? participante.puntos_pick_piloto_14 + participante.puntos_pick_constructor_14 + participante.puntos_posicion1_14 + participante.puntos_posicion2_14 + participante.puntos_posicion3_14 + participante.puntos_posicion4_14 + participante.puntos_posicion5_14
                                                                : (participante.puntos_pick_piloto_15 + participante.puntos_pick_constructor_15 + participante.puntos_posicion1_15 + participante.puntos_posicion2_15 + participante.puntos_posicion3_15 + participante.puntos_posicion4_15 + participante.puntos_posicion5_15 && concursoIndex === '15')
                                                                ? participante.puntos_pick_piloto_15 + participante.puntos_pick_constructor_15 + participante.puntos_posicion1_15 + participante.puntos_posicion2_15 + participante.puntos_posicion3_15 + participante.puntos_posicion4_15 + participante.puntos_posicion5_15
                                                                : (participante.puntos_pick_piloto_16 + participante.puntos_pick_constructor_16 + participante.puntos_posicion1_16 + participante.puntos_posicion2_16 + participante.puntos_posicion3_16 + participante.puntos_posicion4_16 + participante.puntos_posicion5_16 && concursoIndex === '16')
                                                                ? participante.puntos_pick_piloto_16 + participante.puntos_pick_constructor_16 + participante.puntos_posicion1_16 + participante.puntos_posicion2_16 + participante.puntos_posicion3_16 + participante.puntos_posicion4_16 + participante.puntos_posicion5_16
                                                                : (participante.puntos_pick_piloto_17 + participante.puntos_pick_constructor_17 + participante.puntos_posicion1_17 + participante.puntos_posicion2_17 + participante.puntos_posicion3_17 + participante.puntos_posicion4_17 + participante.puntos_posicion5_17 && concursoIndex === '17')
                                                                ? participante.puntos_pick_piloto_17 + participante.puntos_pick_constructor_17 + participante.puntos_posicion1_17 + participante.puntos_posicion2_17 + participante.puntos_posicion3_17 + participante.puntos_posicion4_17 + participante.puntos_posicion5_17
                                                                : (participante.puntos_pick_piloto_18 + participante.puntos_pick_constructor_18 + participante.puntos_posicion1_18 + participante.puntos_posicion2_18 + participante.puntos_posicion3_18 + participante.puntos_posicion4_18 + participante.puntos_posicion5_18 && concursoIndex === '18')
                                                                ? participante.puntos_pick_piloto_18 + participante.puntos_pick_constructor_18 + participante.puntos_posicion1_18 + participante.puntos_posicion2_18 + participante.puntos_posicion3_18 + participante.puntos_posicion4_18 + participante.puntos_posicion5_18
                                                                : (participante.puntos_pick_piloto_19 + participante.puntos_pick_constructor_19 + participante.puntos_posicion1_19 + participante.puntos_posicion2_19 + participante.puntos_posicion3_19 + participante.puntos_posicion4_19 + participante.puntos_posicion5_19 && concursoIndex === '19')
                                                                ? participante.puntos_pick_piloto_19 + participante.puntos_pick_constructor_19 + participante.puntos_posicion1_19 + participante.puntos_posicion2_19 + participante.puntos_posicion3_19 + participante.puntos_posicion4_19 + participante.puntos_posicion5_19
                                                                : (participante.puntos_pick_piloto_20 + participante.puntos_pick_constructor_20 + participante.puntos_posicion1_20 + participante.puntos_posicion2_20 + participante.puntos_posicion3_20 + participante.puntos_posicion4_20 + participante.puntos_posicion5_20 && concursoIndex === '20')
                                                                ? participante.puntos_pick_piloto_20 + participante.puntos_pick_constructor_20 + participante.puntos_posicion1_20 + participante.puntos_posicion2_20 + participante.puntos_posicion3_20 + participante.puntos_posicion4_20 + participante.puntos_posicion5_20
                                                                : (participante.puntos_pick_piloto_21 + participante.puntos_pick_constructor_21 + participante.puntos_posicion1_21 + participante.puntos_posicion2_21 + participante.puntos_posicion3_21 + participante.puntos_posicion4_21 + participante.puntos_posicion5_21 && concursoIndex === '21')
                                                                ? participante.puntos_pick_piloto_21 + participante.puntos_pick_constructor_21 + participante.puntos_posicion1_21 + participante.puntos_posicion2_21 + participante.puntos_posicion3_21 + participante.puntos_posicion4_21 + participante.puntos_posicion5_21
                                                                : (participante.puntos_pick_piloto_22 + participante.puntos_pick_constructor_22 + participante.puntos_posicion1_22 + participante.puntos_posicion2_22 + participante.puntos_posicion3_22 + participante.puntos_posicion4_22 + participante.puntos_posicion5_22 && concursoIndex === '22')
                                                                ? participante.puntos_pick_piloto_22 + participante.puntos_pick_constructor_22 + participante.puntos_posicion1_22 + participante.puntos_posicion2_22 + participante.puntos_posicion3_22 + participante.puntos_posicion4_22 + participante.puntos_posicion5_22
                                                                : (participante.puntos_pick_piloto_23 + participante.puntos_pick_constructor_23 + participante.puntos_posicion1_23 + participante.puntos_posicion2_23 + participante.puntos_posicion3_23 + participante.puntos_posicion4_23 + participante.puntos_posicion5_23 && concursoIndex === '23')
                                                                ? participante.puntos_pick_piloto_23 + participante.puntos_pick_constructor_23 + participante.puntos_posicion1_23 + participante.puntos_posicion2_23 + participante.puntos_posicion3_23 + participante.puntos_posicion4_23 + participante.puntos_posicion5_23
                                                                : (participante.puntos_pick_piloto_24 + participante.puntos_pick_constructor_24 + participante.puntos_posicion1_24 + participante.puntos_posicion2_24 + participante.puntos_posicion3_24 + participante.puntos_posicion4_24 + participante.puntos_posicion5_24 && concursoIndex === '24')
                                                                ? participante.puntos_pick_piloto_24 + participante.puntos_pick_constructor_24 + participante.puntos_posicion1_24 + participante.puntos_posicion2_24 + participante.puntos_posicion3_24 + participante.puntos_posicion4_24 + participante.puntos_posicion5_24
                                                                : 0
                                                            :
                                                                (participante.puntos_pick_piloto_01 + participante.puntos_pick_constructor_01 + participante.puntos_sorteo_piloto_01 + participante.puntos_sorteo_constructor_01 + participante.puntos_pregunta1_01 + participante.puntos_pregunta2_01 + participante.puntos_pregunta3_01 && concursoIndex === '01')
                                                                    ? participante.puntos_pick_piloto_01 + participante.puntos_pick_constructor_01 + participante.puntos_sorteo_piloto_01 + participante.puntos_sorteo_constructor_01 + participante.puntos_pregunta1_01 + participante.puntos_pregunta2_01 + participante.puntos_pregunta3_01
                                                                    : (participante.puntos_pick_piloto_02 + participante.puntos_pick_constructor_02 + participante.puntos_sorteo_piloto_02 + participante.puntos_sorteo_constructor_02 + participante.puntos_pregunta1_02 + participante.puntos_pregunta2_02 + participante.puntos_pregunta3_02 && concursoIndex === '02')
                                                                    ? participante.puntos_pick_piloto_02 + participante.puntos_pick_constructor_02 + participante.puntos_sorteo_piloto_02 + participante.puntos_sorteo_constructor_02 + participante.puntos_pregunta1_02 + participante.puntos_pregunta2_02 + participante.puntos_pregunta3_02
                                                                    : (participante.puntos_pick_piloto_03 + participante.puntos_pick_constructor_03 + participante.puntos_sorteo_piloto_03 + participante.puntos_sorteo_constructor_03 + participante.puntos_pregunta1_03 + participante.puntos_pregunta2_03 + participante.puntos_pregunta3_03 && concursoIndex === '03')
                                                                    ? participante.puntos_pick_piloto_03 + participante.puntos_pick_constructor_03 + participante.puntos_sorteo_piloto_03 + participante.puntos_sorteo_constructor_03 + participante.puntos_pregunta1_03 + participante.puntos_pregunta2_03 + participante.puntos_pregunta3_03
                                                                    : (participante.puntos_pick_piloto_04 + participante.puntos_pick_constructor_04 + participante.puntos_sorteo_piloto_04 + participante.puntos_sorteo_constructor_04 + participante.puntos_pregunta1_04 + participante.puntos_pregunta2_04 + participante.puntos_pregunta3_04 && concursoIndex === '04')
                                                                    ? participante.puntos_pick_piloto_04 + participante.puntos_pick_constructor_04 + participante.puntos_sorteo_piloto_04 + participante.puntos_sorteo_constructor_04 + participante.puntos_pregunta1_04 + participante.puntos_pregunta2_04 + participante.puntos_pregunta3_04
                                                                    : (participante.puntos_pick_piloto_05 + participante.puntos_pick_constructor_05 + participante.puntos_sorteo_piloto_05 + participante.puntos_sorteo_constructor_05 + participante.puntos_pregunta1_05 + participante.puntos_pregunta2_05 + participante.puntos_pregunta3_05 && concursoIndex === '05')
                                                                    ? participante.puntos_pick_piloto_05 + participante.puntos_pick_constructor_05 + participante.puntos_sorteo_piloto_05 + participante.puntos_sorteo_constructor_05 + participante.puntos_pregunta1_05 + participante.puntos_pregunta2_05 + participante.puntos_pregunta3_05
                                                                    : (participante.puntos_pick_piloto_06 + participante.puntos_pick_constructor_06 + participante.puntos_sorteo_piloto_06 + participante.puntos_sorteo_constructor_06 + participante.puntos_pregunta1_06 + participante.puntos_pregunta2_06 + participante.puntos_pregunta3_06 && concursoIndex === '06')
                                                                    ? participante.puntos_pick_piloto_06 + participante.puntos_pick_constructor_06 + participante.puntos_sorteo_piloto_06 + participante.puntos_sorteo_constructor_06 + participante.puntos_pregunta1_06 + participante.puntos_pregunta2_06 + participante.puntos_pregunta3_06
                                                                    : (participante.puntos_pick_piloto_07 + participante.puntos_pick_constructor_07 + participante.puntos_sorteo_piloto_07 + participante.puntos_sorteo_constructor_07 + participante.puntos_pregunta1_07 + participante.puntos_pregunta2_07 + participante.puntos_pregunta3_07 && concursoIndex === '07')
                                                                    ? participante.puntos_pick_piloto_07 + participante.puntos_pick_constructor_07 + participante.puntos_sorteo_piloto_07 + participante.puntos_sorteo_constructor_07 + participante.puntos_pregunta1_07 + participante.puntos_pregunta2_07 + participante.puntos_pregunta3_07
                                                                    : (participante.puntos_pick_piloto_08 + participante.puntos_pick_constructor_08 + participante.puntos_sorteo_piloto_08 + participante.puntos_sorteo_constructor_08 + participante.puntos_pregunta1_08 + participante.puntos_pregunta2_08 + participante.puntos_pregunta3_08 && concursoIndex === '08')
                                                                    ? participante.puntos_pick_piloto_08 + participante.puntos_pick_constructor_08 + participante.puntos_sorteo_piloto_08 + participante.puntos_sorteo_constructor_08 + participante.puntos_pregunta1_08 + participante.puntos_pregunta2_08 + participante.puntos_pregunta3_08
                                                                    : (participante.puntos_pick_piloto_09 + participante.puntos_pick_constructor_09 + participante.puntos_sorteo_piloto_09 + participante.puntos_sorteo_constructor_09 + participante.puntos_pregunta1_09 + participante.puntos_pregunta2_09 + participante.puntos_pregunta3_09 && concursoIndex === '09')
                                                                    ? participante.puntos_pick_piloto_09 + participante.puntos_pick_constructor_09 + participante.puntos_sorteo_piloto_09 + participante.puntos_sorteo_constructor_09 + participante.puntos_pregunta1_09 + participante.puntos_pregunta2_09 + participante.puntos_pregunta3_09
                                                                    : (participante.puntos_pick_piloto_10 + participante.puntos_pick_constructor_10 + participante.puntos_sorteo_piloto_10 + participante.puntos_sorteo_constructor_10 + participante.puntos_pregunta1_10 + participante.puntos_pregunta2_10 + participante.puntos_pregunta3_10 && concursoIndex === '10')
                                                                    ? participante.puntos_pick_piloto_10 + participante.puntos_pick_constructor_10 + participante.puntos_sorteo_piloto_10 + participante.puntos_sorteo_constructor_10 + participante.puntos_pregunta1_10 + participante.puntos_pregunta2_10 + participante.puntos_pregunta3_10
                                                                    : (participante.puntos_pick_piloto_11 + participante.puntos_pick_constructor_11 + participante.puntos_sorteo_piloto_11 + participante.puntos_sorteo_constructor_11 + participante.puntos_pregunta1_11 + participante.puntos_pregunta2_11 + participante.puntos_pregunta3_11 && concursoIndex === '11')
                                                                    ? participante.puntos_pick_piloto_11 + participante.puntos_pick_constructor_11 + participante.puntos_sorteo_piloto_11 + participante.puntos_sorteo_constructor_11 + participante.puntos_pregunta1_11 + participante.puntos_pregunta2_11 + participante.puntos_pregunta3_11
                                                                    : (participante.puntos_pick_piloto_12 + participante.puntos_pick_constructor_12 + participante.puntos_sorteo_piloto_12 + participante.puntos_sorteo_constructor_12 + participante.puntos_pregunta1_12 + participante.puntos_pregunta2_12 + participante.puntos_pregunta3_12 && concursoIndex === '12')
                                                                    ? participante.puntos_pick_piloto_12 + participante.puntos_pick_constructor_12 + participante.puntos_sorteo_piloto_12 + participante.puntos_sorteo_constructor_12 + participante.puntos_pregunta1_12 + participante.puntos_pregunta2_12 + participante.puntos_pregunta3_12
                                                                    : (participante.puntos_pick_piloto_13 + participante.puntos_pick_constructor_13 + participante.puntos_sorteo_piloto_13 + participante.puntos_sorteo_constructor_13 + participante.puntos_pregunta1_13 + participante.puntos_pregunta2_13 + participante.puntos_pregunta3_13 && concursoIndex === '13')
                                                                    ? participante.puntos_pick_piloto_13 + participante.puntos_pick_constructor_13 + participante.puntos_sorteo_piloto_13 + participante.puntos_sorteo_constructor_13 + participante.puntos_pregunta1_13 + participante.puntos_pregunta2_13 + participante.puntos_pregunta3_13
                                                                    : (participante.puntos_pick_piloto_14 + participante.puntos_pick_constructor_14 + participante.puntos_sorteo_piloto_14 + participante.puntos_sorteo_constructor_14 + participante.puntos_pregunta1_14 + participante.puntos_pregunta2_14 + participante.puntos_pregunta3_14 && concursoIndex === '14')
                                                                    ? participante.puntos_pick_piloto_14 + participante.puntos_pick_constructor_14 + participante.puntos_sorteo_piloto_14 + participante.puntos_sorteo_constructor_14 + participante.puntos_pregunta1_14 + participante.puntos_pregunta2_14 + participante.puntos_pregunta3_14
                                                                    : (participante.puntos_pick_piloto_15 + participante.puntos_pick_constructor_15 + participante.puntos_sorteo_piloto_15 + participante.puntos_sorteo_constructor_15 + participante.puntos_pregunta1_15 + participante.puntos_pregunta2_15 + participante.puntos_pregunta3_15 && concursoIndex === '15')
                                                                    ? participante.puntos_pick_piloto_15 + participante.puntos_pick_constructor_15 + participante.puntos_sorteo_piloto_15 + participante.puntos_sorteo_constructor_15 + participante.puntos_pregunta1_15 + participante.puntos_pregunta2_15 + participante.puntos_pregunta3_15
                                                                    : (participante.puntos_pick_piloto_16 + participante.puntos_pick_constructor_16 + participante.puntos_sorteo_piloto_16 + participante.puntos_sorteo_constructor_16 + participante.puntos_pregunta1_16 + participante.puntos_pregunta2_16 + participante.puntos_pregunta3_16 && concursoIndex === '16')
                                                                    ? participante.puntos_pick_piloto_16 + participante.puntos_pick_constructor_16 + participante.puntos_sorteo_piloto_16 + participante.puntos_sorteo_constructor_16 + participante.puntos_pregunta1_16 + participante.puntos_pregunta2_16 + participante.puntos_pregunta3_16
                                                                    : (participante.puntos_pick_piloto_17 + participante.puntos_pick_constructor_17 + participante.puntos_sorteo_piloto_17 + participante.puntos_sorteo_constructor_17 + participante.puntos_pregunta1_17 + participante.puntos_pregunta2_17 + participante.puntos_pregunta3_17 && concursoIndex === '17')
                                                                    ? participante.puntos_pick_piloto_17 + participante.puntos_pick_constructor_17 + participante.puntos_sorteo_piloto_17 + participante.puntos_sorteo_constructor_17 + participante.puntos_pregunta1_17 + participante.puntos_pregunta2_17 + participante.puntos_pregunta3_17
                                                                    : (participante.puntos_pick_piloto_18 + participante.puntos_pick_constructor_18 + participante.puntos_sorteo_piloto_18 + participante.puntos_sorteo_constructor_18 + participante.puntos_pregunta1_18 + participante.puntos_pregunta2_18 + participante.puntos_pregunta3_18 && concursoIndex === '18')
                                                                    ? participante.puntos_pick_piloto_18 + participante.puntos_pick_constructor_18 + participante.puntos_sorteo_piloto_18 + participante.puntos_sorteo_constructor_18 + participante.puntos_pregunta1_18 + participante.puntos_pregunta2_18 + participante.puntos_pregunta3_18
                                                                    : (participante.puntos_pick_piloto_19 + participante.puntos_pick_constructor_19 + participante.puntos_sorteo_piloto_19 + participante.puntos_sorteo_constructor_19 + participante.puntos_pregunta1_19 + participante.puntos_pregunta2_19 + participante.puntos_pregunta3_19 && concursoIndex === '19')
                                                                    ? participante.puntos_pick_piloto_19 + participante.puntos_pick_constructor_19 + participante.puntos_sorteo_piloto_19 + participante.puntos_sorteo_constructor_19 + participante.puntos_pregunta1_19 + participante.puntos_pregunta2_19 + participante.puntos_pregunta3_19
                                                                    : (participante.puntos_pick_piloto_20 + participante.puntos_pick_constructor_20 + participante.puntos_sorteo_piloto_20 + participante.puntos_sorteo_constructor_20 + participante.puntos_pregunta1_20 + participante.puntos_pregunta2_20 + participante.puntos_pregunta3_20 && concursoIndex === '20')
                                                                    ? participante.puntos_pick_piloto_20 + participante.puntos_pick_constructor_20 + participante.puntos_sorteo_piloto_20 + participante.puntos_sorteo_constructor_20 + participante.puntos_pregunta1_20 + participante.puntos_pregunta2_20 + participante.puntos_pregunta3_20
                                                                    : (participante.puntos_pick_piloto_21 + participante.puntos_pick_constructor_21 + participante.puntos_sorteo_piloto_21 + participante.puntos_sorteo_constructor_21 + participante.puntos_pregunta1_21 + participante.puntos_pregunta2_21 + participante.puntos_pregunta3_21 && concursoIndex === '21')
                                                                    ? participante.puntos_pick_piloto_21 + participante.puntos_pick_constructor_21 + participante.puntos_sorteo_piloto_21 + participante.puntos_sorteo_constructor_21 + participante.puntos_pregunta1_21 + participante.puntos_pregunta2_21 + participante.puntos_pregunta3_21
                                                                    : (participante.puntos_pick_piloto_22 + participante.puntos_pick_constructor_22 + participante.puntos_sorteo_piloto_22 + participante.puntos_sorteo_constructor_22 + participante.puntos_pregunta1_22 + participante.puntos_pregunta2_22 + participante.puntos_pregunta3_22 && concursoIndex === '22')
                                                                    ? participante.puntos_pick_piloto_22 + participante.puntos_pick_constructor_22 + participante.puntos_sorteo_piloto_22 + participante.puntos_sorteo_constructor_22 + participante.puntos_pregunta1_22 + participante.puntos_pregunta2_22 + participante.puntos_pregunta3_22
                                                                    : (participante.puntos_pick_piloto_23 + participante.puntos_pick_constructor_23 + participante.puntos_sorteo_piloto_23 + participante.puntos_sorteo_constructor_23 + participante.puntos_pregunta1_23 + participante.puntos_pregunta2_23 + participante.puntos_pregunta3_23 && concursoIndex === '23')
                                                                    ? participante.puntos_pick_piloto_23 + participante.puntos_pick_constructor_23 + participante.puntos_sorteo_piloto_23 + participante.puntos_sorteo_constructor_23 + participante.puntos_pregunta1_23 + participante.puntos_pregunta2_23 + participante.puntos_pregunta3_23
                                                                    : (participante.puntos_pick_piloto_24 + participante.puntos_pick_constructor_24 + participante.puntos_sorteo_piloto_24 + participante.puntos_sorteo_constructor_24 + participante.puntos_pregunta1_24 + participante.puntos_pregunta2_24 + participante.puntos_pregunta3_24 && concursoIndex === '24')
                                                                    ? participante.puntos_pick_piloto_24 + participante.puntos_pick_constructor_24 + participante.puntos_sorteo_piloto_24 + participante.puntos_sorteo_constructor_24 + participante.puntos_pregunta1_24 + participante.puntos_pregunta2_24 + participante.puntos_pregunta3_24
                                                                    : 0
                                                    }
                                                </div>
                                                <div className='info-value'>{participante.nickname}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='strategy-content'>
                                        <div className='gplk-table-indicator'>Estrategia Carrera</div>
                                        <div className='flex-content gplk-row main'>
                                            <div className='info-value small-info'>PILOTOS</div>
                                            <div className='points'>PTS</div>
                                            <div className='points'>PTS</div>
                                            <div className='info-value small-info'>EQUIPOS</div>
                                        </div>
                                        {participantesInscritos.map(participante => 
                                            <div className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null} key={participante._id}>
                                                <div className='info-value small-info'>
                                                    {(participante.pick_piloto_01 && concursoIndex === '01')
                                                        ? participante.pick_piloto_01
                                                        :(participante.pick_piloto_02 && concursoIndex === '02')
                                                        ? participante.pick_piloto_02
                                                        :(participante.pick_piloto_03 && concursoIndex === '03')
                                                        ? participante.pick_piloto_03
                                                        :(participante.pick_piloto_04 && concursoIndex === '04')
                                                        ? participante.pick_piloto_04
                                                        :(participante.pick_piloto_05 && concursoIndex === '05')
                                                        ? participante.pick_piloto_05
                                                        :(participante.pick_piloto_06 && concursoIndex === '06')
                                                        ? participante.pick_piloto_06
                                                        :(participante.pick_piloto_07 && concursoIndex === '07')
                                                        ? participante.pick_piloto_07
                                                        :(participante.pick_piloto_08 && concursoIndex === '08')
                                                        ? participante.pick_piloto_08
                                                        :(participante.pick_piloto_09 && concursoIndex === '09')
                                                        ? participante.pick_piloto_09
                                                        :(participante.pick_piloto_10 && concursoIndex === '10')
                                                        ? participante.pick_piloto_10
                                                        :(participante.pick_piloto_11 && concursoIndex === '11')
                                                        ? participante.pick_piloto_11
                                                        :(participante.pick_piloto_12 && concursoIndex === '12')
                                                        ? participante.pick_piloto_12
                                                        :(participante.pick_piloto_13 && concursoIndex === '13')
                                                        ? participante.pick_piloto_13
                                                        :(participante.pick_piloto_14 && concursoIndex === '14')
                                                        ? participante.pick_piloto_14
                                                        :(participante.pick_piloto_15 && concursoIndex === '15')
                                                        ? participante.pick_piloto_15
                                                        :(participante.pick_piloto_16 && concursoIndex === '16')
                                                        ? participante.pick_piloto_16
                                                        :(participante.pick_piloto_17 && concursoIndex === '17')
                                                        ? participante.pick_piloto_17
                                                        :(participante.pick_piloto_18 && concursoIndex === '18')
                                                        ? participante.pick_piloto_18
                                                        :(participante.pick_piloto_19 && concursoIndex === '19')
                                                        ? participante.pick_piloto_19
                                                        :(participante.pick_piloto_20 && concursoIndex === '20')
                                                        ? participante.pick_piloto_20
                                                        :(participante.pick_piloto_21 && concursoIndex === '21')
                                                        ? participante.pick_piloto_21
                                                        :(participante.pick_piloto_22 && concursoIndex === '22')
                                                        ? participante.pick_piloto_22
                                                        :(participante.pick_piloto_23 && concursoIndex === '23')
                                                        ? participante.pick_piloto_23
                                                        :(participante.pick_piloto_24 && concursoIndex === '24')
                                                        ? participante.pick_piloto_24
                                                        : '-'
                                                    }
                                                </div>
                                                <div className='points'>
                                                    {(participante.puntos_pick_piloto_01 && concursoIndex === '01')
                                                        ? participante.puntos_pick_piloto_01
                                                        : (participante.puntos_pick_piloto_02 && concursoIndex === '02')
                                                        ? participante.puntos_pick_piloto_02
                                                        : (participante.puntos_pick_piloto_03 && concursoIndex === '03')
                                                        ? participante.puntos_pick_piloto_03
                                                        : (participante.puntos_pick_piloto_04 && concursoIndex === '04')
                                                        ? participante.puntos_pick_piloto_04
                                                        : (participante.puntos_pick_piloto_05 && concursoIndex === '05')
                                                        ? participante.puntos_pick_piloto_05
                                                        : (participante.puntos_pick_piloto_06 && concursoIndex === '06')
                                                        ? participante.puntos_pick_piloto_06
                                                        : (participante.puntos_pick_piloto_07 && concursoIndex === '07')
                                                        ? participante.puntos_pick_piloto_07
                                                        : (participante.puntos_pick_piloto_08 && concursoIndex === '08')
                                                        ? participante.puntos_pick_piloto_08
                                                        : (participante.puntos_pick_piloto_09 && concursoIndex === '09')
                                                        ? participante.puntos_pick_piloto_09
                                                        : (participante.puntos_pick_piloto_10 && concursoIndex === '10')
                                                        ? participante.puntos_pick_piloto_10
                                                        : (participante.puntos_pick_piloto_11 && concursoIndex === '11')
                                                        ? participante.puntos_pick_piloto_11
                                                        : (participante.puntos_pick_piloto_12 && concursoIndex === '12')
                                                        ? participante.puntos_pick_piloto_12
                                                        : (participante.puntos_pick_piloto_13 && concursoIndex === '13')
                                                        ? participante.puntos_pick_piloto_13
                                                        : (participante.puntos_pick_piloto_14 && concursoIndex === '14')
                                                        ? participante.puntos_pick_piloto_14
                                                        : (participante.puntos_pick_piloto_15 && concursoIndex === '15')
                                                        ? participante.puntos_pick_piloto_15
                                                        : (participante.puntos_pick_piloto_16 && concursoIndex === '16')
                                                        ? participante.puntos_pick_piloto_16
                                                        : (participante.puntos_pick_piloto_17 && concursoIndex === '17')
                                                        ? participante.puntos_pick_piloto_17
                                                        : (participante.puntos_pick_piloto_18 && concursoIndex === '18')
                                                        ? participante.puntos_pick_piloto_18
                                                        : (participante.puntos_pick_piloto_19 && concursoIndex === '19')
                                                        ? participante.puntos_pick_piloto_19
                                                        : (participante.puntos_pick_piloto_20 && concursoIndex === '20')
                                                        ? participante.puntos_pick_piloto_20
                                                        : (participante.puntos_pick_piloto_21 && concursoIndex === '21')
                                                        ? participante.puntos_pick_piloto_21
                                                        : (participante.puntos_pick_piloto_22 && concursoIndex === '22')
                                                        ? participante.puntos_pick_piloto_22
                                                        : (participante.puntos_pick_piloto_23 && concursoIndex === '23')
                                                        ? participante.puntos_pick_piloto_23
                                                        : (participante.puntos_pick_piloto_24 && concursoIndex === '24')
                                                        ? participante.puntos_pick_piloto_24
                                                        : 0
                                                    }
                                                </div>
                                                <div className='points'>
                                                    {(participante.puntos_pick_constructor_01 && concursoIndex === '01')
                                                        ? participante.puntos_pick_constructor_01
                                                        : (participante.puntos_pick_constructor_02 && concursoIndex === '02')
                                                        ? participante.puntos_pick_constructor_02
                                                        : (participante.puntos_pick_constructor_03 && concursoIndex === '03')
                                                        ? participante.puntos_pick_constructor_03
                                                        : (participante.puntos_pick_constructor_04 && concursoIndex === '04')
                                                        ? participante.puntos_pick_constructor_04
                                                        : (participante.puntos_pick_constructor_05 && concursoIndex === '05')
                                                        ? participante.puntos_pick_constructor_05
                                                        : (participante.puntos_pick_constructor_06 && concursoIndex === '06')
                                                        ? participante.puntos_pick_constructor_06
                                                        : (participante.puntos_pick_constructor_07 && concursoIndex === '07')
                                                        ? participante.puntos_pick_constructor_07
                                                        : (participante.puntos_pick_constructor_08 && concursoIndex === '08')
                                                        ? participante.puntos_pick_constructor_08
                                                        : (participante.puntos_pick_constructor_09 && concursoIndex === '09')
                                                        ? participante.puntos_pick_constructor_09
                                                        : (participante.puntos_pick_constructor_10 && concursoIndex === '10')
                                                        ? participante.puntos_pick_constructor_10
                                                        : (participante.puntos_pick_constructor_11 && concursoIndex === '11')
                                                        ? participante.puntos_pick_constructor_11
                                                        : (participante.puntos_pick_constructor_12 && concursoIndex === '12')
                                                        ? participante.puntos_pick_constructor_12
                                                        : (participante.puntos_pick_constructor_13 && concursoIndex === '13')
                                                        ? participante.puntos_pick_constructor_13
                                                        : (participante.puntos_pick_constructor_14 && concursoIndex === '14')
                                                        ? participante.puntos_pick_constructor_14
                                                        : (participante.puntos_pick_constructor_15 && concursoIndex === '15')
                                                        ? participante.puntos_pick_constructor_15
                                                        : (participante.puntos_pick_constructor_16 && concursoIndex === '16')
                                                        ? participante.puntos_pick_constructor_16
                                                        : (participante.puntos_pick_constructor_17 && concursoIndex === '17')
                                                        ? participante.puntos_pick_constructor_17
                                                        : (participante.puntos_pick_constructor_18 && concursoIndex === '18')
                                                        ? participante.puntos_pick_constructor_18
                                                        : (participante.puntos_pick_constructor_19 && concursoIndex === '19')
                                                        ? participante.puntos_pick_constructor_19
                                                        : (participante.puntos_pick_constructor_20 && concursoIndex === '20')
                                                        ? participante.puntos_pick_constructor_20
                                                        : (participante.puntos_pick_constructor_21 && concursoIndex === '21')
                                                        ? participante.puntos_pick_constructor_21
                                                        : (participante.puntos_pick_constructor_22 && concursoIndex === '22')
                                                        ? participante.puntos_pick_constructor_22
                                                        : (participante.puntos_pick_constructor_23 && concursoIndex === '23')
                                                        ? participante.puntos_pick_constructor_23
                                                        : (participante.puntos_pick_constructor_24 && concursoIndex === '24')
                                                        ? participante.puntos_pick_constructor_24
                                                        : 0
                                                    }
                                                </div>
                                                <div className='info-value small-info'>
                                                    {(participante.pick_constructor_01 && concursoIndex === '01')
                                                        ? participante.pick_constructor_01
                                                        :(participante.pick_constructor_02 && concursoIndex === '02')
                                                        ? participante.pick_constructor_02
                                                        :(participante.pick_constructor_03 && concursoIndex === '03')
                                                        ? participante.pick_constructor_03
                                                        :(participante.pick_constructor_04 && concursoIndex === '04')
                                                        ? participante.pick_constructor_04
                                                        :(participante.pick_constructor_05 && concursoIndex === '05')
                                                        ? participante.pick_constructor_05
                                                        :(participante.pick_constructor_06 && concursoIndex === '06')
                                                        ? participante.pick_constructor_06
                                                        :(participante.pick_constructor_07 && concursoIndex === '07')
                                                        ? participante.pick_constructor_07
                                                        :(participante.pick_constructor_08 && concursoIndex === '08')
                                                        ? participante.pick_constructor_08
                                                        :(participante.pick_constructor_09 && concursoIndex === '09')
                                                        ? participante.pick_constructor_09
                                                        :(participante.pick_constructor_10 && concursoIndex === '10')
                                                        ? participante.pick_constructor_10
                                                        :(participante.pick_constructor_11 && concursoIndex === '11')
                                                        ? participante.pick_constructor_11
                                                        :(participante.pick_constructor_12 && concursoIndex === '12')
                                                        ? participante.pick_constructor_12
                                                        :(participante.pick_constructor_13 && concursoIndex === '13')
                                                        ? participante.pick_constructor_13
                                                        :(participante.pick_constructor_14 && concursoIndex === '14')
                                                        ? participante.pick_constructor_14
                                                        :(participante.pick_constructor_15 && concursoIndex === '15')
                                                        ? participante.pick_constructor_15
                                                        :(participante.pick_constructor_16 && concursoIndex === '16')
                                                        ? participante.pick_constructor_16
                                                        :(participante.pick_constructor_17 && concursoIndex === '17')
                                                        ? participante.pick_constructor_17
                                                        :(participante.pick_constructor_18 && concursoIndex === '18')
                                                        ? participante.pick_constructor_18
                                                        :(participante.pick_constructor_19 && concursoIndex === '19')
                                                        ? participante.pick_constructor_19
                                                        :(participante.pick_constructor_20 && concursoIndex === '20')
                                                        ? participante.pick_constructor_20
                                                        :(participante.pick_constructor_21 && concursoIndex === '21')
                                                        ? participante.pick_constructor_21
                                                        :(participante.pick_constructor_22 && concursoIndex === '22')
                                                        ? participante.pick_constructor_22
                                                        :(participante.pick_constructor_23 && concursoIndex === '23')
                                                        ? participante.pick_constructor_23
                                                        :(participante.pick_constructor_24 && concursoIndex === '24')
                                                        ? participante.pick_constructor_24
                                                        : '-'
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                null
                                            :
                                                <div className='draw-content'>
                                                    <div className='gplk-table-indicator'>Sorteo Carrera</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value small-info'>PILOTOS</div>
                                                        <div className='points'>PTS</div>
                                                        <div className='points'>PTS</div>
                                                        <div className='info-value small-info'>EQUIPOS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null} key={participante._id}>
                                                            <div className='info-value small-info'>
                                                                {(participante.sorteo_piloto_01 && concursoIndex === '01')
                                                                    ? participante.sorteo_piloto_01
                                                                    : (participante.sorteo_piloto_02 && concursoIndex === '02')
                                                                    ? participante.sorteo_piloto_02
                                                                    : (participante.sorteo_piloto_03 && concursoIndex === '03')
                                                                    ? participante.sorteo_piloto_03
                                                                    : (participante.sorteo_piloto_04 && concursoIndex === '04')
                                                                    ? participante.sorteo_piloto_04
                                                                    : (participante.sorteo_piloto_05 && concursoIndex === '05')
                                                                    ? participante.sorteo_piloto_05
                                                                    : (participante.sorteo_piloto_06 && concursoIndex === '06')
                                                                    ? participante.sorteo_piloto_06
                                                                    : (participante.sorteo_piloto_07 && concursoIndex === '07')
                                                                    ? participante.sorteo_piloto_07
                                                                    : (participante.sorteo_piloto_08 && concursoIndex === '08')
                                                                    ? participante.sorteo_piloto_08
                                                                    : (participante.sorteo_piloto_09 && concursoIndex === '09')
                                                                    ? participante.sorteo_piloto_09
                                                                    : (participante.sorteo_piloto_10 && concursoIndex === '10')
                                                                    ? participante.sorteo_piloto_10
                                                                    : (participante.sorteo_piloto_11 && concursoIndex === '11')
                                                                    ? participante.sorteo_piloto_11
                                                                    : (participante.sorteo_piloto_12 && concursoIndex === '12')
                                                                    ? participante.sorteo_piloto_12
                                                                    : (participante.sorteo_piloto_13 && concursoIndex === '13')
                                                                    ? participante.sorteo_piloto_13
                                                                    : (participante.sorteo_piloto_14 && concursoIndex === '14')
                                                                    ? participante.sorteo_piloto_14
                                                                    : (participante.sorteo_piloto_15 && concursoIndex === '15')
                                                                    ? participante.sorteo_piloto_15
                                                                    : (participante.sorteo_piloto_16 && concursoIndex === '16')
                                                                    ? participante.sorteo_piloto_16
                                                                    : (participante.sorteo_piloto_17 && concursoIndex === '17')
                                                                    ? participante.sorteo_piloto_17
                                                                    : (participante.sorteo_piloto_18 && concursoIndex === '18')
                                                                    ? participante.sorteo_piloto_18
                                                                    : (participante.sorteo_piloto_19 && concursoIndex === '19')
                                                                    ? participante.sorteo_piloto_19
                                                                    : (participante.sorteo_piloto_20 && concursoIndex === '20')
                                                                    ? participante.sorteo_piloto_20
                                                                    : (participante.sorteo_piloto_21 && concursoIndex === '21')
                                                                    ? participante.sorteo_piloto_21
                                                                    : (participante.sorteo_piloto_22 && concursoIndex === '22')
                                                                    ? participante.sorteo_piloto_22
                                                                    : (participante.sorteo_piloto_23 && concursoIndex === '23')
                                                                    ? participante.sorteo_piloto_23
                                                                    : (participante.sorteo_piloto_24 && concursoIndex === '24')
                                                                    ? participante.sorteo_piloto_24
                                                                    : '-'
                                                                }
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_sorteo_piloto_01 && concursoIndex === '01')
                                                                    ? participante.puntos_sorteo_piloto_01
                                                                    :(participante.puntos_sorteo_piloto_02 && concursoIndex === '02')
                                                                    ? participante.puntos_sorteo_piloto_02
                                                                    :(participante.puntos_sorteo_piloto_03 && concursoIndex === '03')
                                                                    ? participante.puntos_sorteo_piloto_03
                                                                    :(participante.puntos_sorteo_piloto_04 && concursoIndex === '04')
                                                                    ? participante.puntos_sorteo_piloto_04
                                                                    :(participante.puntos_sorteo_piloto_05 && concursoIndex === '05')
                                                                    ? participante.puntos_sorteo_piloto_05
                                                                    :(participante.puntos_sorteo_piloto_06 && concursoIndex === '06')
                                                                    ? participante.puntos_sorteo_piloto_06
                                                                    :(participante.puntos_sorteo_piloto_07 && concursoIndex === '07')
                                                                    ? participante.puntos_sorteo_piloto_07
                                                                    :(participante.puntos_sorteo_piloto_08 && concursoIndex === '08')
                                                                    ? participante.puntos_sorteo_piloto_08
                                                                    :(participante.puntos_sorteo_piloto_09 && concursoIndex === '09')
                                                                    ? participante.puntos_sorteo_piloto_09
                                                                    :(participante.puntos_sorteo_piloto_10 && concursoIndex === '10')
                                                                    ? participante.puntos_sorteo_piloto_10
                                                                    :(participante.puntos_sorteo_piloto_11 && concursoIndex === '11')
                                                                    ? participante.puntos_sorteo_piloto_11
                                                                    :(participante.puntos_sorteo_piloto_12 && concursoIndex === '12')
                                                                    ? participante.puntos_sorteo_piloto_12
                                                                    :(participante.puntos_sorteo_piloto_13 && concursoIndex === '13')
                                                                    ? participante.puntos_sorteo_piloto_13
                                                                    :(participante.puntos_sorteo_piloto_14 && concursoIndex === '14')
                                                                    ? participante.puntos_sorteo_piloto_14
                                                                    :(participante.puntos_sorteo_piloto_15 && concursoIndex === '15')
                                                                    ? participante.puntos_sorteo_piloto_15
                                                                    :(participante.puntos_sorteo_piloto_16 && concursoIndex === '16')
                                                                    ? participante.puntos_sorteo_piloto_16
                                                                    :(participante.puntos_sorteo_piloto_17 && concursoIndex === '17')
                                                                    ? participante.puntos_sorteo_piloto_17
                                                                    :(participante.puntos_sorteo_piloto_18 && concursoIndex === '18')
                                                                    ? participante.puntos_sorteo_piloto_18
                                                                    :(participante.puntos_sorteo_piloto_19 && concursoIndex === '19')
                                                                    ? participante.puntos_sorteo_piloto_19
                                                                    :(participante.puntos_sorteo_piloto_20 && concursoIndex === '20')
                                                                    ? participante.puntos_sorteo_piloto_20
                                                                    :(participante.puntos_sorteo_piloto_21 && concursoIndex === '21')
                                                                    ? participante.puntos_sorteo_piloto_21
                                                                    :(participante.puntos_sorteo_piloto_22 && concursoIndex === '22')
                                                                    ? participante.puntos_sorteo_piloto_22
                                                                    :(participante.puntos_sorteo_piloto_23 && concursoIndex === '23')
                                                                    ? participante.puntos_sorteo_piloto_23
                                                                    :(participante.puntos_sorteo_piloto_24 && concursoIndex === '24')
                                                                    ? participante.puntos_sorteo_piloto_24
                                                                    : 0
                                                                }
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_sorteo_constructor_01 && concursoIndex === '01')
                                                                    ? participante.puntos_sorteo_constructor_01
                                                                    :(participante.puntos_sorteo_constructor_02 && concursoIndex === '02')
                                                                    ? participante.puntos_sorteo_constructor_02
                                                                    :(participante.puntos_sorteo_constructor_03 && concursoIndex === '03')
                                                                    ? participante.puntos_sorteo_constructor_03
                                                                    :(participante.puntos_sorteo_constructor_04 && concursoIndex === '04')
                                                                    ? participante.puntos_sorteo_constructor_04
                                                                    :(participante.puntos_sorteo_constructor_05 && concursoIndex === '05')
                                                                    ? participante.puntos_sorteo_constructor_05
                                                                    :(participante.puntos_sorteo_constructor_06 && concursoIndex === '06')
                                                                    ? participante.puntos_sorteo_constructor_06
                                                                    :(participante.puntos_sorteo_constructor_07 && concursoIndex === '07')
                                                                    ? participante.puntos_sorteo_constructor_07
                                                                    :(participante.puntos_sorteo_constructor_08 && concursoIndex === '08')
                                                                    ? participante.puntos_sorteo_constructor_08
                                                                    :(participante.puntos_sorteo_constructor_09 && concursoIndex === '09')
                                                                    ? participante.puntos_sorteo_constructor_09
                                                                    :(participante.puntos_sorteo_constructor_10 && concursoIndex === '10')
                                                                    ? participante.puntos_sorteo_constructor_10
                                                                    :(participante.puntos_sorteo_constructor_11 && concursoIndex === '11')
                                                                    ? participante.puntos_sorteo_constructor_11
                                                                    :(participante.puntos_sorteo_constructor_12 && concursoIndex === '12')
                                                                    ? participante.puntos_sorteo_constructor_12
                                                                    :(participante.puntos_sorteo_constructor_13 && concursoIndex === '13')
                                                                    ? participante.puntos_sorteo_constructor_13
                                                                    :(participante.puntos_sorteo_constructor_14 && concursoIndex === '14')
                                                                    ? participante.puntos_sorteo_constructor_14
                                                                    :(participante.puntos_sorteo_constructor_15 && concursoIndex === '15')
                                                                    ? participante.puntos_sorteo_constructor_15
                                                                    :(participante.puntos_sorteo_constructor_16 && concursoIndex === '16')
                                                                    ? participante.puntos_sorteo_constructor_16
                                                                    :(participante.puntos_sorteo_constructor_17 && concursoIndex === '17')
                                                                    ? participante.puntos_sorteo_constructor_17
                                                                    :(participante.puntos_sorteo_constructor_18 && concursoIndex === '18')
                                                                    ? participante.puntos_sorteo_constructor_18
                                                                    :(participante.puntos_sorteo_constructor_19 && concursoIndex === '19')
                                                                    ? participante.puntos_sorteo_constructor_19
                                                                    :(participante.puntos_sorteo_constructor_20 && concursoIndex === '20')
                                                                    ? participante.puntos_sorteo_constructor_20
                                                                    :(participante.puntos_sorteo_constructor_21 && concursoIndex === '21')
                                                                    ? participante.puntos_sorteo_constructor_21
                                                                    :(participante.puntos_sorteo_constructor_22 && concursoIndex === '22')
                                                                    ? participante.puntos_sorteo_constructor_22
                                                                    :(participante.puntos_sorteo_constructor_23 && concursoIndex === '23')
                                                                    ? participante.puntos_sorteo_constructor_23
                                                                    :(participante.puntos_sorteo_constructor_24 && concursoIndex === '24')
                                                                    ? participante.puntos_sorteo_constructor_24
                                                                    : 0
                                                                }
                                                            </div>
                                                            <div className='info-value small-info'>
                                                                {(participante.sorteo_constructor_01 && concursoIndex === '01')
                                                                    ? participante.sorteo_constructor_01
                                                                    :(participante.sorteo_constructor_02 && concursoIndex === '02')
                                                                    ? participante.sorteo_constructor_02
                                                                    :(participante.sorteo_constructor_03 && concursoIndex === '03')
                                                                    ? participante.sorteo_constructor_03
                                                                    :(participante.sorteo_constructor_04 && concursoIndex === '04')
                                                                    ? participante.sorteo_constructor_04
                                                                    :(participante.sorteo_constructor_05 && concursoIndex === '05')
                                                                    ? participante.sorteo_constructor_05
                                                                    :(participante.sorteo_constructor_06 && concursoIndex === '06')
                                                                    ? participante.sorteo_constructor_06
                                                                    :(participante.sorteo_constructor_07 && concursoIndex === '07')
                                                                    ? participante.sorteo_constructor_07
                                                                    :(participante.sorteo_constructor_08 && concursoIndex === '08')
                                                                    ? participante.sorteo_constructor_08
                                                                    :(participante.sorteo_constructor_09 && concursoIndex === '09')
                                                                    ? participante.sorteo_constructor_09
                                                                    :(participante.sorteo_constructor_10 && concursoIndex === '10')
                                                                    ? participante.sorteo_constructor_10
                                                                    :(participante.sorteo_constructor_10 && concursoIndex === '10')
                                                                    ? participante.sorteo_constructor_10
                                                                    :(participante.sorteo_constructor_11 && concursoIndex === '11')
                                                                    ? participante.sorteo_constructor_11
                                                                    :(participante.sorteo_constructor_12 && concursoIndex === '12')
                                                                    ? participante.sorteo_constructor_12
                                                                    :(participante.sorteo_constructor_13 && concursoIndex === '13')
                                                                    ? participante.sorteo_constructor_13
                                                                    :(participante.sorteo_constructor_14 && concursoIndex === '14')
                                                                    ? participante.sorteo_constructor_14
                                                                    :(participante.sorteo_constructor_15 && concursoIndex === '15')
                                                                    ? participante.sorteo_constructor_15
                                                                    :(participante.sorteo_constructor_16 && concursoIndex === '16')
                                                                    ? participante.sorteo_constructor_16
                                                                    :(participante.sorteo_constructor_17 && concursoIndex === '17')
                                                                    ? participante.sorteo_constructor_17
                                                                    :(participante.sorteo_constructor_18 && concursoIndex === '18')
                                                                    ? participante.sorteo_constructor_18
                                                                    :(participante.sorteo_constructor_19 && concursoIndex === '19')
                                                                    ? participante.sorteo_constructor_19
                                                                    :(participante.sorteo_constructor_20 && concursoIndex === '20')
                                                                    ? participante.sorteo_constructor_20
                                                                    :(participante.sorteo_constructor_21 && concursoIndex === '21')
                                                                    ? participante.sorteo_constructor_21
                                                                    :(participante.sorteo_constructor_22 && concursoIndex === '22')
                                                                    ? participante.sorteo_constructor_22
                                                                    :(participante.sorteo_constructor_23 && concursoIndex === '23')
                                                                    ? participante.sorteo_constructor_23
                                                                    :(participante.sorteo_constructor_24 && concursoIndex === '24')
                                                                    ? participante.sorteo_constructor_24
                                                                    : '-'
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                    }
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 1</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>POS 1</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_posicion1_01 && concursoIndex === '01')
                                                                    ? participante.pick_posicion1_01
                                                                    :(participante.pick_posicion1_02 && concursoIndex === '02')
                                                                    ? participante.pick_posicion1_02
                                                                    :(participante.pick_posicion1_03 && concursoIndex === '03')
                                                                    ? participante.pick_posicion1_03
                                                                    :(participante.pick_posicion1_04 && concursoIndex === '04')
                                                                    ? participante.pick_posicion1_04
                                                                    :(participante.pick_posicion1_05 && concursoIndex === '05')
                                                                    ? participante.pick_posicion1_05
                                                                    :(participante.pick_posicion1_06 && concursoIndex === '06')
                                                                    ? participante.pick_posicion1_06
                                                                    :(participante.pick_posicion1_07 && concursoIndex === '07')
                                                                    ? participante.pick_posicion1_07
                                                                    :(participante.pick_posicion1_08 && concursoIndex === '08')
                                                                    ? participante.pick_posicion1_08
                                                                    :(participante.pick_posicion1_09 && concursoIndex === '09')
                                                                    ? participante.pick_posicion1_09
                                                                    :(participante.pick_posicion1_10 && concursoIndex === '10')
                                                                    ? participante.pick_posicion1_10
                                                                    :(participante.pick_posicion1_11 && concursoIndex === '11')
                                                                    ? participante.pick_posicion1_11
                                                                    :(participante.pick_posicion1_12 && concursoIndex === '12')
                                                                    ? participante.pick_posicion1_12
                                                                    :(participante.pick_posicion1_13 && concursoIndex === '13')
                                                                    ? participante.pick_posicion1_13
                                                                    :(participante.pick_posicion1_14 && concursoIndex === '14')
                                                                    ? participante.pick_posicion1_14
                                                                    :(participante.pick_posicion1_15 && concursoIndex === '15')
                                                                    ? participante.pick_posicion1_15
                                                                    :(participante.pick_posicion1_16 && concursoIndex === '16')
                                                                    ? participante.pick_posicion1_16
                                                                    :(participante.pick_posicion1_17 && concursoIndex === '17')
                                                                    ? participante.pick_posicion1_17
                                                                    :(participante.pick_posicion1_18 && concursoIndex === '18')
                                                                    ? participante.pick_posicion1_18
                                                                    :(participante.pick_posicion1_19 && concursoIndex === '19')
                                                                    ? participante.pick_posicion1_19
                                                                    :(participante.pick_posicion1_20 && concursoIndex === '20')
                                                                    ? participante.pick_posicion1_20
                                                                    :(participante.pick_posicion1_21 && concursoIndex === '21')
                                                                    ? participante.pick_posicion1_21
                                                                    :(participante.pick_posicion1_22 && concursoIndex === '22')
                                                                    ? participante.pick_posicion1_22
                                                                    :(participante.pick_posicion1_23 && concursoIndex === '23')
                                                                    ? participante.pick_posicion1_23
                                                                    :(participante.pick_posicion1_24 && concursoIndex === '24')
                                                                    ? participante.pick_posicion1_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_posicion1_01 && concursoIndex === '01')
                                                                    ? participante.puntos_posicion1_01
                                                                    :(participante.puntos_posicion1_02 && concursoIndex === '02')
                                                                    ? participante.puntos_posicion1_02
                                                                    :(participante.puntos_posicion1_03 && concursoIndex === '03')
                                                                    ? participante.puntos_posicion1_03
                                                                    :(participante.puntos_posicion1_04 && concursoIndex === '04')
                                                                    ? participante.puntos_posicion1_04
                                                                    :(participante.puntos_posicion1_05 && concursoIndex === '05')
                                                                    ? participante.puntos_posicion1_05
                                                                    :(participante.puntos_posicion1_06 && concursoIndex === '06')
                                                                    ? participante.puntos_posicion1_06
                                                                    :(participante.puntos_posicion1_07 && concursoIndex === '07')
                                                                    ? participante.puntos_posicion1_07
                                                                    :(participante.puntos_posicion1_08 && concursoIndex === '08')
                                                                    ? participante.puntos_posicion1_08
                                                                    :(participante.puntos_posicion1_09 && concursoIndex === '09')
                                                                    ? participante.puntos_posicion1_09
                                                                    :(participante.puntos_posicion1_10 && concursoIndex === '10')
                                                                    ? participante.puntos_posicion1_10
                                                                    :(participante.puntos_posicion1_11 && concursoIndex === '11')
                                                                    ? participante.puntos_posicion1_11
                                                                    :(participante.puntos_posicion1_12 && concursoIndex === '12')
                                                                    ? participante.puntos_posicion1_12
                                                                    :(participante.puntos_posicion1_13 && concursoIndex === '13')
                                                                    ? participante.puntos_posicion1_13
                                                                    :(participante.puntos_posicion1_14 && concursoIndex === '14')
                                                                    ? participante.puntos_posicion1_14
                                                                    :(participante.puntos_posicion1_15 && concursoIndex === '15')
                                                                    ? participante.puntos_posicion1_15
                                                                    :(participante.puntos_posicion1_16 && concursoIndex === '16')
                                                                    ? participante.puntos_posicion1_16
                                                                    :(participante.puntos_posicion1_17 && concursoIndex === '17')
                                                                    ? participante.puntos_posicion1_17
                                                                    :(participante.puntos_posicion1_18 && concursoIndex === '18')
                                                                    ? participante.puntos_posicion1_18
                                                                    :(participante.puntos_posicion1_19 && concursoIndex === '19')
                                                                    ? participante.puntos_posicion1_19
                                                                    :(participante.puntos_posicion1_20 && concursoIndex === '20')
                                                                    ? participante.puntos_posicion1_20
                                                                    :(participante.puntos_posicion1_21 && concursoIndex === '21')
                                                                    ? participante.puntos_posicion1_21
                                                                    :(participante.puntos_posicion1_22 && concursoIndex === '22')
                                                                    ? participante.puntos_posicion1_22
                                                                    :(participante.puntos_posicion1_23 && concursoIndex === '23')
                                                                    ? participante.puntos_posicion1_23
                                                                    :(participante.puntos_posicion1_24 && concursoIndex === '24')
                                                                    ? participante.puntos_posicion1_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            :
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 1</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>"Checo"</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_pregunta1_01 && concursoIndex === '01')
                                                                    ? participante.pick_pregunta1_01
                                                                    :(participante.pick_pregunta1_02 && concursoIndex === '02')
                                                                    ? participante.pick_pregunta1_02
                                                                    :(participante.pick_pregunta1_03 && concursoIndex === '03')
                                                                    ? participante.pick_pregunta1_03
                                                                    :(participante.pick_pregunta1_04 && concursoIndex === '04')
                                                                    ? participante.pick_pregunta1_04
                                                                    :(participante.pick_pregunta1_05 && concursoIndex === '05')
                                                                    ? participante.pick_pregunta1_05
                                                                    :(participante.pick_pregunta1_06 && concursoIndex === '06')
                                                                    ? participante.pick_pregunta1_06
                                                                    :(participante.pick_pregunta1_07 && concursoIndex === '07')
                                                                    ? participante.pick_pregunta1_07
                                                                    :(participante.pick_pregunta1_08 && concursoIndex === '08')
                                                                    ? participante.pick_pregunta1_08
                                                                    :(participante.pick_pregunta1_09 && concursoIndex === '09')
                                                                    ? participante.pick_pregunta1_09
                                                                    :(participante.pick_pregunta1_10 && concursoIndex === '10')
                                                                    ? participante.pick_pregunta1_10
                                                                    :(participante.pick_pregunta1_11 && concursoIndex === '11')
                                                                    ? participante.pick_pregunta1_11
                                                                    :(participante.pick_pregunta1_12 && concursoIndex === '12')
                                                                    ? participante.pick_pregunta1_12
                                                                    :(participante.pick_pregunta1_13 && concursoIndex === '13')
                                                                    ? participante.pick_pregunta1_13
                                                                    :(participante.pick_pregunta1_14 && concursoIndex === '14')
                                                                    ? participante.pick_pregunta1_14
                                                                    :(participante.pick_pregunta1_15 && concursoIndex === '15')
                                                                    ? participante.pick_pregunta1_15
                                                                    :(participante.pick_pregunta1_16 && concursoIndex === '16')
                                                                    ? participante.pick_pregunta1_16
                                                                    :(participante.pick_pregunta1_17 && concursoIndex === '17')
                                                                    ? participante.pick_pregunta1_17
                                                                    :(participante.pick_pregunta1_18 && concursoIndex === '18')
                                                                    ? participante.pick_pregunta1_18
                                                                    :(participante.pick_pregunta1_19 && concursoIndex === '19')
                                                                    ? participante.pick_pregunta1_19
                                                                    :(participante.pick_pregunta1_20 && concursoIndex === '20')
                                                                    ? participante.pick_pregunta1_20
                                                                    :(participante.pick_pregunta1_21 && concursoIndex === '21')
                                                                    ? participante.pick_pregunta1_21
                                                                    :(participante.pick_pregunta1_22 && concursoIndex === '22')
                                                                    ? participante.pick_pregunta1_22
                                                                    :(participante.pick_pregunta1_23 && concursoIndex === '23')
                                                                    ? participante.pick_pregunta1_23
                                                                    :(participante.pick_pregunta1_24 && concursoIndex === '24')
                                                                    ? participante.pick_pregunta1_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_pregunta1_01 && concursoIndex === '01')
                                                                    ? participante.puntos_pregunta1_01
                                                                    :(participante.puntos_pregunta1_02 && concursoIndex === '02')
                                                                    ? participante.puntos_pregunta1_02
                                                                    :(participante.puntos_pregunta1_03 && concursoIndex === '03')
                                                                    ? participante.puntos_pregunta1_03
                                                                    :(participante.puntos_pregunta1_04 && concursoIndex === '04')
                                                                    ? participante.puntos_pregunta1_04
                                                                    :(participante.puntos_pregunta1_05 && concursoIndex === '05')
                                                                    ? participante.puntos_pregunta1_05
                                                                    :(participante.puntos_pregunta1_06 && concursoIndex === '06')
                                                                    ? participante.puntos_pregunta1_06
                                                                    :(participante.puntos_pregunta1_07 && concursoIndex === '07')
                                                                    ? participante.puntos_pregunta1_07
                                                                    :(participante.puntos_pregunta1_08 && concursoIndex === '08')
                                                                    ? participante.puntos_pregunta1_08
                                                                    :(participante.puntos_pregunta1_09 && concursoIndex === '09')
                                                                    ? participante.puntos_pregunta1_09
                                                                    :(participante.puntos_pregunta1_10 && concursoIndex === '10')
                                                                    ? participante.puntos_pregunta1_10
                                                                    :(participante.puntos_pregunta1_11 && concursoIndex === '11')
                                                                    ? participante.puntos_pregunta1_11
                                                                    :(participante.puntos_pregunta1_12 && concursoIndex === '12')
                                                                    ? participante.puntos_pregunta1_12
                                                                    :(participante.puntos_pregunta1_13 && concursoIndex === '13')
                                                                    ? participante.puntos_pregunta1_13
                                                                    :(participante.puntos_pregunta1_14 && concursoIndex === '14')
                                                                    ? participante.puntos_pregunta1_14
                                                                    :(participante.puntos_pregunta1_15 && concursoIndex === '15')
                                                                    ? participante.puntos_pregunta1_15
                                                                    :(participante.puntos_pregunta1_16 && concursoIndex === '16')
                                                                    ? participante.puntos_pregunta1_16
                                                                    :(participante.puntos_pregunta1_17 && concursoIndex === '17')
                                                                    ? participante.puntos_pregunta1_17
                                                                    :(participante.puntos_pregunta1_18 && concursoIndex === '18')
                                                                    ? participante.puntos_pregunta1_18
                                                                    :(participante.puntos_pregunta1_19 && concursoIndex === '19')
                                                                    ? participante.puntos_pregunta1_19
                                                                    :(participante.puntos_pregunta1_20 && concursoIndex === '20')
                                                                    ? participante.puntos_pregunta1_20
                                                                    :(participante.puntos_pregunta1_21 && concursoIndex === '21')
                                                                    ? participante.puntos_pregunta1_21
                                                                    :(participante.puntos_pregunta1_22 && concursoIndex === '22')
                                                                    ? participante.puntos_pregunta1_22
                                                                    :(participante.puntos_pregunta1_23 && concursoIndex === '23')
                                                                    ? participante.puntos_pregunta1_23
                                                                    :(participante.puntos_pregunta1_24 && concursoIndex === '24')
                                                                    ? participante.puntos_pregunta1_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                    }
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 2</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>POS 2</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_posicion2_01 && concursoIndex === '01')
                                                                    ? participante.pick_posicion2_01
                                                                    :(participante.pick_posicion2_02 && concursoIndex === '02')
                                                                    ? participante.pick_posicion2_02
                                                                    :(participante.pick_posicion2_03 && concursoIndex === '03')
                                                                    ? participante.pick_posicion2_03
                                                                    :(participante.pick_posicion2_04 && concursoIndex === '04')
                                                                    ? participante.pick_posicion2_04
                                                                    :(participante.pick_posicion2_05 && concursoIndex === '05')
                                                                    ? participante.pick_posicion2_05
                                                                    :(participante.pick_posicion2_06 && concursoIndex === '06')
                                                                    ? participante.pick_posicion2_06
                                                                    :(participante.pick_posicion2_07 && concursoIndex === '07')
                                                                    ? participante.pick_posicion2_07
                                                                    :(participante.pick_posicion2_08 && concursoIndex === '08')
                                                                    ? participante.pick_posicion2_08
                                                                    :(participante.pick_posicion2_09 && concursoIndex === '09')
                                                                    ? participante.pick_posicion2_09
                                                                    :(participante.pick_posicion2_10 && concursoIndex === '10')
                                                                    ? participante.pick_posicion2_10
                                                                    :(participante.pick_posicion2_11 && concursoIndex === '11')
                                                                    ? participante.pick_posicion2_11
                                                                    :(participante.pick_posicion2_12 && concursoIndex === '12')
                                                                    ? participante.pick_posicion2_12
                                                                    :(participante.pick_posicion2_13 && concursoIndex === '13')
                                                                    ? participante.pick_posicion2_13
                                                                    :(participante.pick_posicion2_14 && concursoIndex === '14')
                                                                    ? participante.pick_posicion2_14
                                                                    :(participante.pick_posicion2_15 && concursoIndex === '15')
                                                                    ? participante.pick_posicion2_15
                                                                    :(participante.pick_posicion2_16 && concursoIndex === '16')
                                                                    ? participante.pick_posicion2_16
                                                                    :(participante.pick_posicion2_17 && concursoIndex === '17')
                                                                    ? participante.pick_posicion2_17
                                                                    :(participante.pick_posicion2_18 && concursoIndex === '18')
                                                                    ? participante.pick_posicion2_18
                                                                    :(participante.pick_posicion2_19 && concursoIndex === '19')
                                                                    ? participante.pick_posicion2_19
                                                                    :(participante.pick_posicion2_20 && concursoIndex === '20')
                                                                    ? participante.pick_posicion2_20
                                                                    :(participante.pick_posicion2_21 && concursoIndex === '21')
                                                                    ? participante.pick_posicion2_21
                                                                    :(participante.pick_posicion2_22 && concursoIndex === '22')
                                                                    ? participante.pick_posicion2_22
                                                                    :(participante.pick_posicion2_23 && concursoIndex === '23')
                                                                    ? participante.pick_posicion2_23
                                                                    :(participante.pick_posicion2_24 && concursoIndex === '24')
                                                                    ? participante.pick_posicion2_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_posicion2_01 && concursoIndex === '01')
                                                                    ? participante.puntos_posicion2_01
                                                                    :(participante.puntos_posicion2_02 && concursoIndex === '02')
                                                                    ? participante.puntos_posicion2_02
                                                                    :(participante.puntos_posicion2_03 && concursoIndex === '03')
                                                                    ? participante.puntos_posicion2_03
                                                                    :(participante.puntos_posicion2_04 && concursoIndex === '04')
                                                                    ? participante.puntos_posicion2_04
                                                                    :(participante.puntos_posicion2_05 && concursoIndex === '05')
                                                                    ? participante.puntos_posicion2_05
                                                                    :(participante.puntos_posicion2_06 && concursoIndex === '06')
                                                                    ? participante.puntos_posicion2_06
                                                                    :(participante.puntos_posicion2_07 && concursoIndex === '07')
                                                                    ? participante.puntos_posicion2_07
                                                                    :(participante.puntos_posicion2_08 && concursoIndex === '08')
                                                                    ? participante.puntos_posicion2_08
                                                                    :(participante.puntos_posicion2_09 && concursoIndex === '09')
                                                                    ? participante.puntos_posicion2_09
                                                                    :(participante.puntos_posicion2_10 && concursoIndex === '10')
                                                                    ? participante.puntos_posicion2_10
                                                                    :(participante.puntos_posicion2_11 && concursoIndex === '11')
                                                                    ? participante.puntos_posicion2_11
                                                                    :(participante.puntos_posicion2_12 && concursoIndex === '12')
                                                                    ? participante.puntos_posicion2_12
                                                                    :(participante.puntos_posicion2_13 && concursoIndex === '13')
                                                                    ? participante.puntos_posicion2_13
                                                                    :(participante.puntos_posicion2_14 && concursoIndex === '14')
                                                                    ? participante.puntos_posicion2_14
                                                                    :(participante.puntos_posicion2_15 && concursoIndex === '15')
                                                                    ? participante.puntos_posicion2_15
                                                                    :(participante.puntos_posicion2_16 && concursoIndex === '16')
                                                                    ? participante.puntos_posicion2_16
                                                                    :(participante.puntos_posicion2_17 && concursoIndex === '17')
                                                                    ? participante.puntos_posicion2_17
                                                                    :(participante.puntos_posicion2_18 && concursoIndex === '18')
                                                                    ? participante.puntos_posicion2_18
                                                                    :(participante.puntos_posicion2_19 && concursoIndex === '19')
                                                                    ? participante.puntos_posicion2_19
                                                                    :(participante.puntos_posicion2_20 && concursoIndex === '20')
                                                                    ? participante.puntos_posicion2_20
                                                                    :(participante.puntos_posicion2_21 && concursoIndex === '21')
                                                                    ? participante.puntos_posicion2_21
                                                                    :(participante.puntos_posicion2_22 && concursoIndex === '22')
                                                                    ? participante.puntos_posicion2_22
                                                                    :(participante.puntos_posicion2_23 && concursoIndex === '23')
                                                                    ? participante.puntos_posicion2_23
                                                                    :(participante.puntos_posicion2_24 && concursoIndex === '24')
                                                                    ? participante.puntos_posicion2_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            :
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 2</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>DHL</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_pregunta2_01 && concursoIndex === '01')
                                                                    ? participante.pick_pregunta2_01
                                                                    :(participante.pick_pregunta2_02 && concursoIndex === '02')
                                                                    ? participante.pick_pregunta2_02
                                                                    :(participante.pick_pregunta2_03 && concursoIndex === '03')
                                                                    ? participante.pick_pregunta2_03
                                                                    :(participante.pick_pregunta2_04 && concursoIndex === '04')
                                                                    ? participante.pick_pregunta2_04
                                                                    :(participante.pick_pregunta2_05 && concursoIndex === '05')
                                                                    ? participante.pick_pregunta2_05
                                                                    :(participante.pick_pregunta2_06 && concursoIndex === '06')
                                                                    ? participante.pick_pregunta2_06
                                                                    :(participante.pick_pregunta2_07 && concursoIndex === '07')
                                                                    ? participante.pick_pregunta2_07
                                                                    :(participante.pick_pregunta2_08 && concursoIndex === '08')
                                                                    ? participante.pick_pregunta2_08
                                                                    :(participante.pick_pregunta2_09 && concursoIndex === '09')
                                                                    ? participante.pick_pregunta2_09
                                                                    :(participante.pick_pregunta2_10 && concursoIndex === '10')
                                                                    ? participante.pick_pregunta2_10
                                                                    :(participante.pick_pregunta2_11 && concursoIndex === '11')
                                                                    ? participante.pick_pregunta2_11
                                                                    :(participante.pick_pregunta2_12 && concursoIndex === '12')
                                                                    ? participante.pick_pregunta2_12
                                                                    :(participante.pick_pregunta2_13 && concursoIndex === '13')
                                                                    ? participante.pick_pregunta2_13
                                                                    :(participante.pick_pregunta2_14 && concursoIndex === '14')
                                                                    ? participante.pick_pregunta2_14
                                                                    :(participante.pick_pregunta2_15 && concursoIndex === '15')
                                                                    ? participante.pick_pregunta2_15
                                                                    :(participante.pick_pregunta2_16 && concursoIndex === '16')
                                                                    ? participante.pick_pregunta2_16
                                                                    :(participante.pick_pregunta2_17 && concursoIndex === '17')
                                                                    ? participante.pick_pregunta2_17
                                                                    :(participante.pick_pregunta2_18 && concursoIndex === '18')
                                                                    ? participante.pick_pregunta2_18
                                                                    :(participante.pick_pregunta2_19 && concursoIndex === '19')
                                                                    ? participante.pick_pregunta2_19
                                                                    :(participante.pick_pregunta2_20 && concursoIndex === '20')
                                                                    ? participante.pick_pregunta2_20
                                                                    :(participante.pick_pregunta2_21 && concursoIndex === '21')
                                                                    ? participante.pick_pregunta2_21
                                                                    :(participante.pick_pregunta2_22 && concursoIndex === '22')
                                                                    ? participante.pick_pregunta2_22
                                                                    :(participante.pick_pregunta2_23 && concursoIndex === '23')
                                                                    ? participante.pick_pregunta2_23
                                                                    :(participante.pick_pregunta2_24 && concursoIndex === '24')
                                                                    ? participante.pick_pregunta2_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_pregunta2_01 && concursoIndex === '01')
                                                                    ? participante.puntos_pregunta2_01
                                                                    :(participante.puntos_pregunta2_02 && concursoIndex === '02')
                                                                    ? participante.puntos_pregunta2_02
                                                                    :(participante.puntos_pregunta2_03 && concursoIndex === '03')
                                                                    ? participante.puntos_pregunta2_03
                                                                    :(participante.puntos_pregunta2_04 && concursoIndex === '04')
                                                                    ? participante.puntos_pregunta2_04
                                                                    :(participante.puntos_pregunta2_05 && concursoIndex === '05')
                                                                    ? participante.puntos_pregunta2_05
                                                                    :(participante.puntos_pregunta2_06 && concursoIndex === '06')
                                                                    ? participante.puntos_pregunta2_06
                                                                    :(participante.puntos_pregunta2_07 && concursoIndex === '07')
                                                                    ? participante.puntos_pregunta2_07
                                                                    :(participante.puntos_pregunta2_08 && concursoIndex === '08')
                                                                    ? participante.puntos_pregunta2_08
                                                                    :(participante.puntos_pregunta2_09 && concursoIndex === '09')
                                                                    ? participante.puntos_pregunta2_09
                                                                    :(participante.puntos_pregunta2_10 && concursoIndex === '10')
                                                                    ? participante.puntos_pregunta2_10
                                                                    :(participante.puntos_pregunta2_11 && concursoIndex === '11')
                                                                    ? participante.puntos_pregunta2_11
                                                                    :(participante.puntos_pregunta2_12 && concursoIndex === '12')
                                                                    ? participante.puntos_pregunta2_12
                                                                    :(participante.puntos_pregunta2_13 && concursoIndex === '13')
                                                                    ? participante.puntos_pregunta2_13
                                                                    :(participante.puntos_pregunta2_14 && concursoIndex === '14')
                                                                    ? participante.puntos_pregunta2_14
                                                                    :(participante.puntos_pregunta2_15 && concursoIndex === '15')
                                                                    ? participante.puntos_pregunta2_15
                                                                    :(participante.puntos_pregunta2_16 && concursoIndex === '16')
                                                                    ? participante.puntos_pregunta2_16
                                                                    :(participante.puntos_pregunta2_17 && concursoIndex === '17')
                                                                    ? participante.puntos_pregunta2_17
                                                                    :(participante.puntos_pregunta2_18 && concursoIndex === '18')
                                                                    ? participante.puntos_pregunta2_18
                                                                    :(participante.puntos_pregunta2_19 && concursoIndex === '19')
                                                                    ? participante.puntos_pregunta2_19
                                                                    :(participante.puntos_pregunta2_20 && concursoIndex === '20')
                                                                    ? participante.puntos_pregunta2_20
                                                                    :(participante.puntos_pregunta2_21 && concursoIndex === '21')
                                                                    ? participante.puntos_pregunta2_21
                                                                    :(participante.puntos_pregunta2_22 && concursoIndex === '22')
                                                                    ? participante.puntos_pregunta2_22
                                                                    :(participante.puntos_pregunta2_23 && concursoIndex === '23')
                                                                    ? participante.puntos_pregunta2_23
                                                                    :(participante.puntos_pregunta2_24 && concursoIndex === '24')
                                                                    ? participante.puntos_pregunta2_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                    }
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 3</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>POS 3</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_posicion3_01 && concursoIndex === '01')
                                                                    ? participante.pick_posicion3_01
                                                                    :(participante.pick_posicion3_02 && concursoIndex === '02')
                                                                    ? participante.pick_posicion3_02
                                                                    :(participante.pick_posicion3_03 && concursoIndex === '03')
                                                                    ? participante.pick_posicion3_03
                                                                    :(participante.pick_posicion3_04 && concursoIndex === '04')
                                                                    ? participante.pick_posicion3_04
                                                                    :(participante.pick_posicion3_05 && concursoIndex === '05')
                                                                    ? participante.pick_posicion3_05
                                                                    :(participante.pick_posicion3_06 && concursoIndex === '06')
                                                                    ? participante.pick_posicion3_06
                                                                    :(participante.pick_posicion3_07 && concursoIndex === '07')
                                                                    ? participante.pick_posicion3_07
                                                                    :(participante.pick_posicion3_08 && concursoIndex === '08')
                                                                    ? participante.pick_posicion3_08
                                                                    :(participante.pick_posicion3_09 && concursoIndex === '09')
                                                                    ? participante.pick_posicion3_09
                                                                    :(participante.pick_posicion3_10 && concursoIndex === '10')
                                                                    ? participante.pick_posicion3_10
                                                                    :(participante.pick_posicion3_11 && concursoIndex === '11')
                                                                    ? participante.pick_posicion3_11
                                                                    :(participante.pick_posicion3_12 && concursoIndex === '12')
                                                                    ? participante.pick_posicion3_12
                                                                    :(participante.pick_posicion3_13 && concursoIndex === '13')
                                                                    ? participante.pick_posicion3_13
                                                                    :(participante.pick_posicion3_14 && concursoIndex === '14')
                                                                    ? participante.pick_posicion3_14
                                                                    :(participante.pick_posicion3_15 && concursoIndex === '15')
                                                                    ? participante.pick_posicion3_15
                                                                    :(participante.pick_posicion3_16 && concursoIndex === '16')
                                                                    ? participante.pick_posicion3_16
                                                                    :(participante.pick_posicion3_17 && concursoIndex === '17')
                                                                    ? participante.pick_posicion3_17
                                                                    :(participante.pick_posicion3_18 && concursoIndex === '18')
                                                                    ? participante.pick_posicion3_18
                                                                    :(participante.pick_posicion3_19 && concursoIndex === '19')
                                                                    ? participante.pick_posicion3_19
                                                                    :(participante.pick_posicion3_20 && concursoIndex === '20')
                                                                    ? participante.pick_posicion3_20
                                                                    :(participante.pick_posicion3_21 && concursoIndex === '21')
                                                                    ? participante.pick_posicion3_21
                                                                    :(participante.pick_posicion3_22 && concursoIndex === '22')
                                                                    ? participante.pick_posicion3_22
                                                                    :(participante.pick_posicion3_23 && concursoIndex === '23')
                                                                    ? participante.pick_posicion3_23
                                                                    :(participante.pick_posicion3_24 && concursoIndex === '24')
                                                                    ? participante.pick_posicion3_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_posicion3_01 && concursoIndex === '01')
                                                                    ? participante.puntos_posicion3_01
                                                                    :(participante.puntos_posicion3_02 && concursoIndex === '02')
                                                                    ? participante.puntos_posicion3_02
                                                                    :(participante.puntos_posicion3_03 && concursoIndex === '03')
                                                                    ? participante.puntos_posicion3_03
                                                                    :(participante.puntos_posicion3_04 && concursoIndex === '04')
                                                                    ? participante.puntos_posicion3_04
                                                                    :(participante.puntos_posicion3_05 && concursoIndex === '05')
                                                                    ? participante.puntos_posicion3_05
                                                                    :(participante.puntos_posicion3_06 && concursoIndex === '06')
                                                                    ? participante.puntos_posicion3_06
                                                                    :(participante.puntos_posicion3_07 && concursoIndex === '07')
                                                                    ? participante.puntos_posicion3_07
                                                                    :(participante.puntos_posicion3_08 && concursoIndex === '08')
                                                                    ? participante.puntos_posicion3_08
                                                                    :(participante.puntos_posicion3_09 && concursoIndex === '09')
                                                                    ? participante.puntos_posicion3_09
                                                                    :(participante.puntos_posicion3_10 && concursoIndex === '10')
                                                                    ? participante.puntos_posicion3_10
                                                                    :(participante.puntos_posicion3_11 && concursoIndex === '11')
                                                                    ? participante.puntos_posicion3_11
                                                                    :(participante.puntos_posicion3_12 && concursoIndex === '12')
                                                                    ? participante.puntos_posicion3_12
                                                                    :(participante.puntos_posicion3_13 && concursoIndex === '13')
                                                                    ? participante.puntos_posicion3_13
                                                                    :(participante.puntos_posicion3_14 && concursoIndex === '14')
                                                                    ? participante.puntos_posicion3_14
                                                                    :(participante.puntos_posicion3_15 && concursoIndex === '15')
                                                                    ? participante.puntos_posicion3_15
                                                                    :(participante.puntos_posicion3_16 && concursoIndex === '16')
                                                                    ? participante.puntos_posicion3_16
                                                                    :(participante.puntos_posicion3_17 && concursoIndex === '17')
                                                                    ? participante.puntos_posicion3_17
                                                                    :(participante.puntos_posicion3_18 && concursoIndex === '18')
                                                                    ? participante.puntos_posicion3_18
                                                                    :(participante.puntos_posicion3_19 && concursoIndex === '19')
                                                                    ? participante.puntos_posicion3_19
                                                                    :(participante.puntos_posicion3_20 && concursoIndex === '20')
                                                                    ? participante.puntos_posicion3_20
                                                                    :(participante.puntos_posicion3_21 && concursoIndex === '21')
                                                                    ? participante.puntos_posicion3_21
                                                                    :(participante.puntos_posicion3_22 && concursoIndex === '22')
                                                                    ? participante.puntos_posicion3_22
                                                                    :(participante.puntos_posicion3_23 && concursoIndex === '23')
                                                                    ? participante.puntos_posicion3_23
                                                                    :(participante.puntos_posicion3_24 && concursoIndex === '24')
                                                                    ? participante.puntos_posicion3_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            :
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 3</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>DNF</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_pregunta3_01 && concursoIndex === '01')
                                                                    ? participante.pick_pregunta3_01
                                                                    :(participante.pick_pregunta3_02 && concursoIndex === '02')
                                                                    ? participante.pick_pregunta3_02
                                                                    :(participante.pick_pregunta3_03 && concursoIndex === '03')
                                                                    ? participante.pick_pregunta3_03
                                                                    :(participante.pick_pregunta3_04 && concursoIndex === '04')
                                                                    ? participante.pick_pregunta3_04
                                                                    :(participante.pick_pregunta3_05 && concursoIndex === '05')
                                                                    ? participante.pick_pregunta3_05
                                                                    :(participante.pick_pregunta3_06 && concursoIndex === '06')
                                                                    ? participante.pick_pregunta3_06
                                                                    :(participante.pick_pregunta3_07 && concursoIndex === '07')
                                                                    ? participante.pick_pregunta3_07
                                                                    :(participante.pick_pregunta3_08 && concursoIndex === '08')
                                                                    ? participante.pick_pregunta3_08
                                                                    :(participante.pick_pregunta3_09 && concursoIndex === '09')
                                                                    ? participante.pick_pregunta3_09
                                                                    :(participante.pick_pregunta3_10 && concursoIndex === '10')
                                                                    ? participante.pick_pregunta3_10
                                                                    :(participante.pick_pregunta3_11 && concursoIndex === '11')
                                                                    ? participante.pick_pregunta3_11
                                                                    :(participante.pick_pregunta3_12 && concursoIndex === '12')
                                                                    ? participante.pick_pregunta3_12
                                                                    :(participante.pick_pregunta3_13 && concursoIndex === '13')
                                                                    ? participante.pick_pregunta3_13
                                                                    :(participante.pick_pregunta3_14 && concursoIndex === '14')
                                                                    ? participante.pick_pregunta3_14
                                                                    :(participante.pick_pregunta3_15 && concursoIndex === '15')
                                                                    ? participante.pick_pregunta3_15
                                                                    :(participante.pick_pregunta3_16 && concursoIndex === '16')
                                                                    ? participante.pick_pregunta3_16
                                                                    :(participante.pick_pregunta3_17 && concursoIndex === '17')
                                                                    ? participante.pick_pregunta3_17
                                                                    :(participante.pick_pregunta3_18 && concursoIndex === '18')
                                                                    ? participante.pick_pregunta3_18
                                                                    :(participante.pick_pregunta3_19 && concursoIndex === '19')
                                                                    ? participante.pick_pregunta3_19
                                                                    :(participante.pick_pregunta3_20 && concursoIndex === '20')
                                                                    ? participante.pick_pregunta3_20
                                                                    :(participante.pick_pregunta3_21 && concursoIndex === '21')
                                                                    ? participante.pick_pregunta3_21
                                                                    :(participante.pick_pregunta3_22 && concursoIndex === '22')
                                                                    ? participante.pick_pregunta3_22
                                                                    :(participante.pick_pregunta3_23 && concursoIndex === '23')
                                                                    ? participante.pick_pregunta3_23
                                                                    :(participante.pick_pregunta3_24 && concursoIndex === '24')
                                                                    ? participante.pick_pregunta3_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_pregunta3_01 && concursoIndex === '01')
                                                                    ? participante.puntos_pregunta3_01
                                                                    :(participante.puntos_pregunta3_02 && concursoIndex === '02')
                                                                    ? participante.puntos_pregunta3_02
                                                                    :(participante.puntos_pregunta3_03 && concursoIndex === '03')
                                                                    ? participante.puntos_pregunta3_03
                                                                    :(participante.puntos_pregunta3_04 && concursoIndex === '04')
                                                                    ? participante.puntos_pregunta3_04
                                                                    :(participante.puntos_pregunta3_05 && concursoIndex === '05')
                                                                    ? participante.puntos_pregunta3_05
                                                                    :(participante.puntos_pregunta3_06 && concursoIndex === '06')
                                                                    ? participante.puntos_pregunta3_06
                                                                    :(participante.puntos_pregunta3_07 && concursoIndex === '07')
                                                                    ? participante.puntos_pregunta3_07
                                                                    :(participante.puntos_pregunta3_08 && concursoIndex === '08')
                                                                    ? participante.puntos_pregunta3_08
                                                                    :(participante.puntos_pregunta3_09 && concursoIndex === '09')
                                                                    ? participante.puntos_pregunta3_09
                                                                    :(participante.puntos_pregunta3_10 && concursoIndex === '10')
                                                                    ? participante.puntos_pregunta3_10
                                                                    :(participante.puntos_pregunta3_11 && concursoIndex === '11')
                                                                    ? participante.puntos_pregunta3_11
                                                                    :(participante.puntos_pregunta3_12 && concursoIndex === '12')
                                                                    ? participante.puntos_pregunta3_12
                                                                    :(participante.puntos_pregunta3_13 && concursoIndex === '13')
                                                                    ? participante.puntos_pregunta3_13
                                                                    :(participante.puntos_pregunta3_14 && concursoIndex === '14')
                                                                    ? participante.puntos_pregunta3_14
                                                                    :(participante.puntos_pregunta3_15 && concursoIndex === '15')
                                                                    ? participante.puntos_pregunta3_15
                                                                    :(participante.puntos_pregunta3_16 && concursoIndex === '16')
                                                                    ? participante.puntos_pregunta3_16
                                                                    :(participante.puntos_pregunta3_17 && concursoIndex === '17')
                                                                    ? participante.puntos_pregunta3_17
                                                                    :(participante.puntos_pregunta3_18 && concursoIndex === '18')
                                                                    ? participante.puntos_pregunta3_18
                                                                    :(participante.puntos_pregunta3_19 && concursoIndex === '19')
                                                                    ? participante.puntos_pregunta3_19
                                                                    :(participante.puntos_pregunta3_20 && concursoIndex === '20')
                                                                    ? participante.puntos_pregunta3_20
                                                                    :(participante.puntos_pregunta3_21 && concursoIndex === '21')
                                                                    ? participante.puntos_pregunta3_21
                                                                    :(participante.puntos_pregunta3_22 && concursoIndex === '22')
                                                                    ? participante.puntos_pregunta3_22
                                                                    :(participante.puntos_pregunta3_23 && concursoIndex === '23')
                                                                    ? participante.puntos_pregunta3_23
                                                                    :(participante.puntos_pregunta3_24 && concursoIndex === '24')
                                                                    ? participante.puntos_pregunta3_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                    }
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 4</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>POS 4</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_posicion4_01 && concursoIndex === '01')
                                                                    ? participante.pick_posicion4_01
                                                                    :(participante.pick_posicion4_02 && concursoIndex === '02')
                                                                    ? participante.pick_posicion4_02
                                                                    :(participante.pick_posicion4_03 && concursoIndex === '03')
                                                                    ? participante.pick_posicion4_03
                                                                    :(participante.pick_posicion4_04 && concursoIndex === '04')
                                                                    ? participante.pick_posicion4_04
                                                                    :(participante.pick_posicion4_05 && concursoIndex === '05')
                                                                    ? participante.pick_posicion4_05
                                                                    :(participante.pick_posicion4_06 && concursoIndex === '06')
                                                                    ? participante.pick_posicion4_06
                                                                    :(participante.pick_posicion4_07 && concursoIndex === '07')
                                                                    ? participante.pick_posicion4_07
                                                                    :(participante.pick_posicion4_08 && concursoIndex === '08')
                                                                    ? participante.pick_posicion4_08
                                                                    :(participante.pick_posicion4_09 && concursoIndex === '09')
                                                                    ? participante.pick_posicion4_09
                                                                    :(participante.pick_posicion4_10 && concursoIndex === '10')
                                                                    ? participante.pick_posicion4_10
                                                                    :(participante.pick_posicion4_11 && concursoIndex === '11')
                                                                    ? participante.pick_posicion4_11
                                                                    :(participante.pick_posicion4_12 && concursoIndex === '12')
                                                                    ? participante.pick_posicion4_12
                                                                    :(participante.pick_posicion4_13 && concursoIndex === '13')
                                                                    ? participante.pick_posicion4_13
                                                                    :(participante.pick_posicion4_14 && concursoIndex === '14')
                                                                    ? participante.pick_posicion4_14
                                                                    :(participante.pick_posicion4_15 && concursoIndex === '15')
                                                                    ? participante.pick_posicion4_15
                                                                    :(participante.pick_posicion4_16 && concursoIndex === '16')
                                                                    ? participante.pick_posicion4_16
                                                                    :(participante.pick_posicion4_17 && concursoIndex === '17')
                                                                    ? participante.pick_posicion4_17
                                                                    :(participante.pick_posicion4_18 && concursoIndex === '18')
                                                                    ? participante.pick_posicion4_18
                                                                    :(participante.pick_posicion4_19 && concursoIndex === '19')
                                                                    ? participante.pick_posicion4_19
                                                                    :(participante.pick_posicion4_20 && concursoIndex === '20')
                                                                    ? participante.pick_posicion4_20
                                                                    :(participante.pick_posicion4_21 && concursoIndex === '21')
                                                                    ? participante.pick_posicion4_21
                                                                    :(participante.pick_posicion4_22 && concursoIndex === '22')
                                                                    ? participante.pick_posicion4_22
                                                                    :(participante.pick_posicion4_23 && concursoIndex === '23')
                                                                    ? participante.pick_posicion4_23
                                                                    :(participante.pick_posicion4_24 && concursoIndex === '24')
                                                                    ? participante.pick_posicion4_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_posicion4_01 && concursoIndex === '01')
                                                                    ? participante.puntos_posicion4_01
                                                                    :(participante.puntos_posicion4_02 && concursoIndex === '02')
                                                                    ? participante.puntos_posicion4_02
                                                                    :(participante.puntos_posicion4_03 && concursoIndex === '03')
                                                                    ? participante.puntos_posicion4_03
                                                                    :(participante.puntos_posicion4_04 && concursoIndex === '04')
                                                                    ? participante.puntos_posicion4_04
                                                                    :(participante.puntos_posicion4_05 && concursoIndex === '05')
                                                                    ? participante.puntos_posicion4_05
                                                                    :(participante.puntos_posicion4_06 && concursoIndex === '06')
                                                                    ? participante.puntos_posicion4_06
                                                                    :(participante.puntos_posicion4_07 && concursoIndex === '07')
                                                                    ? participante.puntos_posicion4_07
                                                                    :(participante.puntos_posicion4_08 && concursoIndex === '08')
                                                                    ? participante.puntos_posicion4_08
                                                                    :(participante.puntos_posicion4_09 && concursoIndex === '09')
                                                                    ? participante.puntos_posicion4_09
                                                                    :(participante.puntos_posicion4_10 && concursoIndex === '10')
                                                                    ? participante.puntos_posicion4_10
                                                                    :(participante.puntos_posicion4_11 && concursoIndex === '11')
                                                                    ? participante.puntos_posicion4_11
                                                                    :(participante.puntos_posicion4_12 && concursoIndex === '12')
                                                                    ? participante.puntos_posicion4_12
                                                                    :(participante.puntos_posicion4_13 && concursoIndex === '13')
                                                                    ? participante.puntos_posicion4_13
                                                                    :(participante.puntos_posicion4_14 && concursoIndex === '14')
                                                                    ? participante.puntos_posicion4_14
                                                                    :(participante.puntos_posicion4_15 && concursoIndex === '15')
                                                                    ? participante.puntos_posicion4_15
                                                                    :(participante.puntos_posicion4_16 && concursoIndex === '16')
                                                                    ? participante.puntos_posicion4_16
                                                                    :(participante.puntos_posicion4_17 && concursoIndex === '17')
                                                                    ? participante.puntos_posicion4_17
                                                                    :(participante.puntos_posicion4_18 && concursoIndex === '18')
                                                                    ? participante.puntos_posicion4_18
                                                                    :(participante.puntos_posicion4_19 && concursoIndex === '19')
                                                                    ? participante.puntos_posicion4_19
                                                                    :(participante.puntos_posicion4_20 && concursoIndex === '20')
                                                                    ? participante.puntos_posicion4_20
                                                                    :(participante.puntos_posicion4_21 && concursoIndex === '21')
                                                                    ? participante.puntos_posicion4_21
                                                                    :(participante.puntos_posicion4_22 && concursoIndex === '22')
                                                                    ? participante.puntos_posicion4_22
                                                                    :(participante.puntos_posicion4_23 && concursoIndex === '23')
                                                                    ? participante.puntos_posicion4_23
                                                                    :(participante.puntos_posicion4_24 && concursoIndex === '24')
                                                                    ? participante.puntos_posicion4_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            :
                                                null
                                    }
                                    {
                                        (concurso.nombre === 'GOAT')
                                            ?
                                                <div className='question-content'>
                                                    <div className='gplk-table-indicator'>Pregunta 5</div>
                                                    <div className='flex-content gplk-row main'>
                                                        <div className='info-value question-value question'>POS 5</div>
                                                        <div className='points'>PTS</div>
                                                    </div>
                                                    {participantesInscritos.map(participante => 
                                                        <div key={participante._id} className='flex-content gplk-row' id={(participante.id_participante === usuario._id) ? 'active': null}>
                                                            <div className='info-value question'>
                                                                {(participante.pick_posicion5_01 && concursoIndex === '01')
                                                                    ? participante.pick_posicion5_01
                                                                    :(participante.pick_posicion5_02 && concursoIndex === '02')
                                                                    ? participante.pick_posicion5_02
                                                                    :(participante.pick_posicion5_03 && concursoIndex === '03')
                                                                    ? participante.pick_posicion5_03
                                                                    :(participante.pick_posicion5_04 && concursoIndex === '04')
                                                                    ? participante.pick_posicion5_04
                                                                    :(participante.pick_posicion5_05 && concursoIndex === '05')
                                                                    ? participante.pick_posicion5_05
                                                                    :(participante.pick_posicion5_06 && concursoIndex === '06')
                                                                    ? participante.pick_posicion5_06
                                                                    :(participante.pick_posicion5_07 && concursoIndex === '07')
                                                                    ? participante.pick_posicion5_07
                                                                    :(participante.pick_posicion5_08 && concursoIndex === '08')
                                                                    ? participante.pick_posicion5_08
                                                                    :(participante.pick_posicion5_09 && concursoIndex === '09')
                                                                    ? participante.pick_posicion5_09
                                                                    :(participante.pick_posicion5_10 && concursoIndex === '10')
                                                                    ? participante.pick_posicion5_10
                                                                    :(participante.pick_posicion5_11 && concursoIndex === '11')
                                                                    ? participante.pick_posicion5_11
                                                                    :(participante.pick_posicion5_12 && concursoIndex === '12')
                                                                    ? participante.pick_posicion5_12
                                                                    :(participante.pick_posicion5_13 && concursoIndex === '13')
                                                                    ? participante.pick_posicion5_13
                                                                    :(participante.pick_posicion5_14 && concursoIndex === '14')
                                                                    ? participante.pick_posicion5_14
                                                                    :(participante.pick_posicion5_15 && concursoIndex === '15')
                                                                    ? participante.pick_posicion5_15
                                                                    :(participante.pick_posicion5_16 && concursoIndex === '16')
                                                                    ? participante.pick_posicion5_16
                                                                    :(participante.pick_posicion5_17 && concursoIndex === '17')
                                                                    ? participante.pick_posicion5_17
                                                                    :(participante.pick_posicion5_18 && concursoIndex === '18')
                                                                    ? participante.pick_posicion5_18
                                                                    :(participante.pick_posicion5_19 && concursoIndex === '19')
                                                                    ? participante.pick_posicion5_19
                                                                    :(participante.pick_posicion5_20 && concursoIndex === '20')
                                                                    ? participante.pick_posicion5_20
                                                                    :(participante.pick_posicion5_21 && concursoIndex === '21')
                                                                    ? participante.pick_posicion5_21
                                                                    :(participante.pick_posicion5_22 && concursoIndex === '22')
                                                                    ? participante.pick_posicion5_22
                                                                    :(participante.pick_posicion5_23 && concursoIndex === '23')
                                                                    ? participante.pick_posicion5_23
                                                                    :(participante.pick_posicion5_24 && concursoIndex === '24')
                                                                    ? participante.pick_posicion5_24
                                                                    : '-'}
                                                            </div>
                                                            <div className='points'>
                                                                {(participante.puntos_posicion5_01 && concursoIndex === '01')
                                                                    ? participante.puntos_posicion5_01
                                                                    :(participante.puntos_posicion5_02 && concursoIndex === '02')
                                                                    ? participante.puntos_posicion5_02
                                                                    :(participante.puntos_posicion5_03 && concursoIndex === '03')
                                                                    ? participante.puntos_posicion5_03
                                                                    :(participante.puntos_posicion5_04 && concursoIndex === '04')
                                                                    ? participante.puntos_posicion5_04
                                                                    :(participante.puntos_posicion5_05 && concursoIndex === '05')
                                                                    ? participante.puntos_posicion5_05
                                                                    :(participante.puntos_posicion5_06 && concursoIndex === '06')
                                                                    ? participante.puntos_posicion5_06
                                                                    :(participante.puntos_posicion5_07 && concursoIndex === '07')
                                                                    ? participante.puntos_posicion5_07
                                                                    :(participante.puntos_posicion5_08 && concursoIndex === '08')
                                                                    ? participante.puntos_posicion5_08
                                                                    :(participante.puntos_posicion5_09 && concursoIndex === '09')
                                                                    ? participante.puntos_posicion5_09
                                                                    :(participante.puntos_posicion5_10 && concursoIndex === '10')
                                                                    ? participante.puntos_posicion5_10
                                                                    :(participante.puntos_posicion5_11 && concursoIndex === '11')
                                                                    ? participante.puntos_posicion5_11
                                                                    :(participante.puntos_posicion5_12 && concursoIndex === '12')
                                                                    ? participante.puntos_posicion5_12
                                                                    :(participante.puntos_posicion5_13 && concursoIndex === '13')
                                                                    ? participante.puntos_posicion5_13
                                                                    :(participante.puntos_posicion5_14 && concursoIndex === '14')
                                                                    ? participante.puntos_posicion5_14
                                                                    :(participante.puntos_posicion5_15 && concursoIndex === '15')
                                                                    ? participante.puntos_posicion5_15
                                                                    :(participante.puntos_posicion5_16 && concursoIndex === '16')
                                                                    ? participante.puntos_posicion5_16
                                                                    :(participante.puntos_posicion5_17 && concursoIndex === '17')
                                                                    ? participante.puntos_posicion5_17
                                                                    :(participante.puntos_posicion5_18 && concursoIndex === '18')
                                                                    ? participante.puntos_posicion5_18
                                                                    :(participante.puntos_posicion5_19 && concursoIndex === '19')
                                                                    ? participante.puntos_posicion5_19
                                                                    :(participante.puntos_posicion5_20 && concursoIndex === '20')
                                                                    ? participante.puntos_posicion5_20
                                                                    :(participante.puntos_posicion5_21 && concursoIndex === '21')
                                                                    ? participante.puntos_posicion5_21
                                                                    :(participante.puntos_posicion5_22 && concursoIndex === '22')
                                                                    ? participante.puntos_posicion5_22
                                                                    :(participante.puntos_posicion5_23 && concursoIndex === '23')
                                                                    ? participante.puntos_posicion5_23
                                                                    :(participante.puntos_posicion5_24 && concursoIndex === '24')
                                                                    ? participante.puntos_posicion5_24
                                                                    : 0}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            :
                                                null
                                    }
                                </div>
                            </div>
                            <Link to={'/carreras'} className='back'>
                                <div className='icon'>
                                    <img
                                        src={backIcon}
                                        alt="Regresar"
                                    />
                                </div>
                                <div className='text'>Regresar</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ResultadoCarrera;